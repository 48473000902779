import catalogModule from "../catalogModule";

function searchOptions(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.searchOptionsTemplate
  };
}

catalogModule.directive('searchOptions', searchOptions);

export default searchOptions;
