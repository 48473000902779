import { CurrentUser, Endpoints, EnvironmentUrls } from 'Roblox';
import catalogModule from '../catalogModule';

function catalogService(
  $log,
  $q,
  httpService,
  eventStreamService,
  catalogConstants,
  utilityService
) {
  'ngInject';

  return {
    getMetadataFromApi() {
      return httpService.httpGet(catalogConstants.endpoints.getMetadata);
    },

    getSearchOptions() {
      const urlConfig = catalogConstants.endpoints.getSearchOptionsUrl;

      return httpService.httpGet(urlConfig);
    },

    getNavigationMenuItems() {
      return httpService.httpGet(catalogConstants.endpoints.getNavigationMenuItems);
    },

    getSearchItems(params, isFullScreenEnabled, showExpandedResults = false) {
      const {
        numberOfSearchItems,
        numberOfSearchItemsForFullScreen,
        numberOfSearchItemsExpanded
      } = catalogConstants;
      let limit = isFullScreenEnabled ? numberOfSearchItemsForFullScreen : numberOfSearchItems;
      if (showExpandedResults) {
        limit = numberOfSearchItemsExpanded;
      }
      const urlParams = {
        limit
      };
      Object.assign(urlParams, params);
      return httpService.httpGet(catalogConstants.endpoints.getSearchItems, urlParams);
    },

    getCatalogItemDetails(itemsMapKey) {
      const { endpoints, priceStatus } = catalogConstants;
      const items = [];
      for (const key in itemsMapKey) {
        if (Object.prototype.hasOwnProperty.call(itemsMapKey, key)) {
          items.push(itemsMapKey[key]);
        }
      }
      const requestData = {
        items
      };
      return httpService
        .httpPost(endpoints.getCatalogItemDetails, requestData)
        .then(function success(result) {
          const returnResult = result && result.data ? result.data : [];
          returnResult.forEach(item => {
            const { id } = item;
            item.key = itemsMapKey[id].key;
            if (item.priceStatus === priceStatus.free) {
              item.isFree = true;
            }
            utilityService.mapItemRestrictionIcons(item);
            utilityService.mapItemStatusIconsAndLabels(item);
            utilityService.buildUserLink(item);
          });
          return returnResult;
        });
    },

    getAvatarRequestSuggestion(search, languageCode, limit, previousQuery, useFallback = false) {
      let lang = languageCode;
      if (lang === null) {
        lang = catalogConstants.englishLanguageCode;
      }
      const params = {
        prefix: search,
        limit,
        lang,
        q: previousQuery
      };

      if (useFallback) {
        return httpService.httpGet(catalogConstants.endpoints.avatarRequestSuggestion, params);
      }
      return httpService.httpGet(catalogConstants.endpoints.avatarRequestCdnSuggestion, params);
    },

    postGetTopics(items, selectTopics, inputQuery) {
      const validInputQuery = !(
        inputQuery === undefined ||
        inputQuery === null ||
        inputQuery === ''
      );
      const urlParams = {
        maxResult: catalogConstants.topics.maxTopicsToRequest,
        items: !validInputQuery ? items : [],
        selectTopics,
        inputQuery: validInputQuery ? inputQuery : undefined
      };
      return httpService.httpPost(catalogConstants.endpoints.postGetTopics, urlParams);
    },

    getUserCurrency() {
      const { getUserCurrency } = catalogConstants.endpoints;
      const url =
        EnvironmentUrls.economyApi +
        Endpoints.generateAbsoluteUrl(getUserCurrency.url, { userId: CurrentUser.userId }, true);
      getUserCurrency.url = url;
      return httpService.httpGet(getUserCurrency);
    },

    postMarketplaceItemDetails(collectibleItemId) {
      const urlParams = {
        itemIds: [collectibleItemId]
      };
      return httpService.httpPost(catalogConstants.endpoints.postMarketplaceItemDetails, urlParams);
    },
    getResellerData(assetId) {
      const urlConfig = {
        url: catalogConstants.getResellerDataUrl(assetId),
        retryable: true,
        withCredentials: true
      };
      return httpService.httpGet(urlConfig);
    }
  };
}

catalogModule.factory('catalogService', catalogService);

export default catalogService;
