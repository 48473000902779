import catalogModule from '../catalogModule';

function catalogBreadcrumbs(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.catalogBreadcrumbsTemplate
  };
}

catalogModule.directive('catalogBreadcrumbs', catalogBreadcrumbs);

export default catalogBreadcrumbs;
