import { EventStream } from 'Roblox';
import { pageName } from 'core-utilities';
import catalogConstants from '../constants/catalogConstants';

export function sendSearchAutocompleteEvent(
  algorithm,
  suggestions,
  kwd,
  previouskwd,
  locale,
  latency,
  suggestionLimit,
  isPersonalizedBasedOnPreviousQuery
) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.autocompleteSuggestionEventData.searchAutocompleteEvent,
      catalogConstants.autocompleteSuggestionEventData.searchType,
      {
        algorithm,
        maxResults: suggestionLimit,
        timeoutDelay:
          catalogConstants.autocompleteSuggestionEventData.autocompleteSuggestionEventTimeoutDelay,
        suggestions,
        kwd,
        previouskwd,
        page: pageName.PageNameProvider.getInternalPageName(),
        searchBox: catalogConstants.autocompleteSuggestionEventData.searchBox,
        locale,
        searchType: catalogConstants.autocompleteSuggestionEventData.searchType,
        latency,
        suggestionSource: catalogConstants.autocompleteSuggestionEventData.suggestionSource,
        isPersonalizedBasedOnPreviousQuery
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendSearchTextTrimEvent(kwd, resultingkwd) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.autocompleteSuggestionEventData.searchTextTrimEvent,
      catalogConstants.autocompleteSuggestionEventData.searchType,
      {
        kwd,
        resultingkwd,
        searchType: catalogConstants.autocompleteSuggestionEventData.searchType
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendCatalogSearchEvent(autoCompleteFlag) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.autocompleteSuggestionEventData.catalogSearchEvent,
      catalogConstants.autocompleteSuggestionEventData.searchType,
      {
        autoCompleteFlag,
        previousPage: pageName.PageNameProvider.getInternalPageName()
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendSearchClearEvent(kwd) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.autocompleteSuggestionEventData.searchClearEvent,
      catalogConstants.autocompleteSuggestionEventData.searchType,
      {
        kwd,
        searchType: catalogConstants.autocompleteSuggestionEventData.searchType,
        page: pageName.PageNameProvider.getInternalPageName()
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendSearchSuggestionClickedEvent(
  kwd,
  suggestionPosition,
  suggestionClicked,
  suggestions
) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.autocompleteSuggestionEventData.searchSuggestionClickedEvent,
      catalogConstants.autocompleteSuggestionEventData.searchType,
      {
        kwd,
        searchType: catalogConstants.autocompleteSuggestionEventData.searchType,
        suggestionPosition,
        suggestionClicked,
        suggestions,
        maxResults: 0
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendTopicSelectedEvent(
  topic,
  rank,
  categoryName,
  subcategoryName,
  query,
  allTopics,
  previousSelectedTopics
) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.topicEventData.topicSelectEventName,
      catalogConstants.topicEventData.topicEventContext,
      {
        topic,
        rank: rank + 1,
        categoryName,
        subcategoryName,
        query,
        allTopics,
        previousSelectedTopics
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendTopicDeselectedEvent(
  topic,
  rank,
  categoryName,
  subcategoryName,
  query,
  allTopics
) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.topicEventData.topicDeselectEventName,
      catalogConstants.topicEventData.topicEventContext,
      {
        topic,
        rank: rank + 1,
        categoryName,
        subcategoryName,
        query,
        allTopics
      },
      EventStream.TargetTypes.WWW
    );
  }
}

export function sendTopicClearedEvent(categoryName, subcategoryName, query, allTopics) {
  if (EventStream) {
    EventStream.SendEventWithTarget(
      catalogConstants.topicEventData.topicClearEventName,
      catalogConstants.topicEventData.topicEventContext,
      {
        categoryName,
        subcategoryName,
        query,
        allTopics
      },
      EventStream.TargetTypes.WWW
    );
  }
}
