import catalogModule from '../catalogModule';

function mobileSearchBar(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.mobileSearchBarTemplate
  };
}

catalogModule.directive('mobileSearchBar', mobileSearchBar);

export default mobileSearchBar;
