import catalogModule from '../catalogModule';
// Consider to introduce globally after getting some user feedback
function imageLoad($timeout) {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      element.bind('load', () => {
        if (scope.item && scope.item.thumbnail) {
          $timeout(() => {
            scope.item.thumbnail.isLoaded = true;
          });
        }
      });
    }
  };
}

catalogModule.directive('imageLoad', imageLoad);

export default imageLoad;
