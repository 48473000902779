export type QueryParams = {
  Keyword?: string;
  TriggeredByTopicDiscovery?: boolean;
  topics?: string;
  Category?: number;
  Subcategory?: number;
  Gears?: number;
  CreatorID?: number | 'custom';
  CreatorType?: string;
  CreatorName?: string;
  CurrencyType?: number;
  pxMin?: number | null;
  pxMax?: number | null;
  salesTypeFilter?: number;
  SortType?: number;
  SortAggregation?: number;
  IncludeNotForSale?: true;
};

export type QueryParamsKey = keyof QueryParams;

const queryParamsExample: QueryParams = {
  Keyword: 'example keyword',
  TriggeredByTopicDiscovery: true,
  topics: 'example topic',
  Category: 1,
  Subcategory: 2,
  Gears: 3,
  CreatorID: 1234,
  CreatorType: 'example type',
  CreatorName: 'Example Name',
  CurrencyType: 1,
  pxMin: 100,
  pxMax: 1000,
  salesTypeFilter: 2,
  SortType: 1,
  SortAggregation: 1,
  IncludeNotForSale: true
};

export const QueryParamKeys: QueryParamsKey[] = Object.keys(queryParamsExample) as QueryParamsKey[];

export function isQueryParamKey(key: string): key is QueryParamsKey {
  return QueryParamKeys.includes(key as QueryParamsKey);
}
