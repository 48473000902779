import catalogModule from '../catalogModule';

function showUnavailableFilter(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.showUnavailableFilterTemplate
  };
}

catalogModule.directive('showUnavailableFilter', showUnavailableFilter);

export default showUnavailableFilter;
