import catalogModule from '../catalogModule';
import menuConstants from '../constants/menuConstants';

function breadcrumbsController($log, $scope) {
  'ngInject';

  $scope.isSortAvailable = () => {
    return $scope.isSearchableCategory($scope.currentQuery.category);
  };

  $scope.showCreatorBreadcrumb = () => {
    return $scope.data.creatorBreadcrumb && $scope.data.creatorBreadcrumb.length > 0;
  };

  $scope.showPriceBreadcrumb = () => {
    return $scope.showFreeBreadcrumb() || $scope.showCustomPriceBreadcrumb();
  };

  $scope.showFreeBreadcrumb = () => {
    return $scope.data.showFreeBreadcrumb;
  };

  $scope.showCustomPriceBreadcrumb = () => {
    if (
      !$scope.data.currencyType ||
      $scope.data.currencyType.currencyType !== $scope.library.customRobuxFilterId
    ) {
      return false;
    }

    if (!$scope.data.minPriceBreadcrumb && !$scope.data.maxPriceBreadcrumb) {
      $scope.data.showFreeBreadcrumb = true;
      return false;
    }

    const { minPriceBreadcrumb } = $scope.data;
    return typeof minPriceBreadcrumb === 'number' && minPriceBreadcrumb % 1 === 0;
  };

  $scope.isNonDefaultSubcategorySelected = () => {
    const cat = $scope.getCategoryOption($scope.currentQuery.category);

    if (!cat || !cat.subcategories || !$scope.currentQuery.subcategory) {
      return false;
    }

    const defaultSubcategory = cat.subcategories[0];
    return defaultSubcategory.subcategoryId !== $scope.currentQuery.subcategory.subcategoryId;
  };

  $scope.clearSubcategory = () => {
    const cat = $scope.getCategoryOption($scope.currentQuery.category);
    // Set subcategory to the default for the category if the category has a subcategory menu
    if (!cat || !cat.subcategories) {
      $scope.currentQuery.subcategory = null;
    } else {
      const defaultSubcategory = cat.subcategories[0];
      $scope.currentQuery.subcategory = defaultSubcategory;
    }

    $scope.clearKeywordAndCreator();
  };
}

catalogModule.controller('breadcrumbsController', breadcrumbsController);

export default breadcrumbsController;
