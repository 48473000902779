import catalogModule from '../catalogModule';
import menuConstants from '../constants/menuConstants';

function searchOptionsController($log, $scope, catalogConstants) {
  'ngInject';

  $scope.canShowFilters = () => {
    return $scope.isSearchableCategory($scope.currentQuery.category);
  };

  $scope.canToggleSubmenu = category => {
    const { subcategories } = category;
    return subcategories && subcategories.length > 0;
  };

  $scope.isUGCOnly = () => {
    const result =
      $scope.currentQuery?.category?.categoryId === menuConstants.categoryTypes.CommunityCreations;
    return result;
  };
}

catalogModule.controller('searchOptionsController', searchOptionsController);

export default searchOptionsController;
