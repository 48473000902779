import angular from 'angular';
import catalogModule from '../catalogModule';

function catalogContentController($log, $scope) {
  'ngInject';

  $scope.initializeScrollbar = function () {
    const scrollbarElement = angular.element(
      document.getElementById('search-options-scrollbar-container')
    );
    if (scrollbarElement !== null && $scope.simpleBar === undefined) {
      scrollbarElement.mCustomScrollbar({
        autoHideScrollbar: true,
        autoExpandScrollbar: false,
        scrollInertia: 1,
        contentTouchScroll: 1,
        mouseWheel: {
          preventDefault: true
        }
      });
    }
  };

  $scope.isInitializedPage = () => {
    const { initialized, isPhone, isApp } = $scope.library;
    $scope.initializeScrollbar();
    return initialized && !(isPhone && isApp);
  };
}

catalogModule.controller('catalogContentController', catalogContentController);

export default catalogContentController;
