import React from 'react';
import useShoppingCart from '../hooks/useShoppingCart';
import { catalogTranslations } from '../services/translationService';

type TAddToCartButtonProps = {
  itemId: number;
  itemType: string;
  collectibleItemId?: string | null;
  itemName: string;
  buttonClass?: string;
};

const AddToCartButton = ({
  itemId,
  itemType,
  collectibleItemId,
  itemName,
  buttonClass
}: TAddToCartButtonProps): JSX.Element => {
  const { isItemInCart, addItemToCart, removeItemFromCart } = useShoppingCart();
  let isInCart = isItemInCart(itemId);
  const getShoppingCartButtonClasses = () => {
    const btnClass = buttonClass !== undefined ? buttonClass : '';
    return `${btnClass} ${!isInCart ? 'btn-primary-lg' : 'btn-secondary-lg'}`;
  };

  return (
    <div className='btn-container shopping-cart-add-remove-btn-container'>
      <button
        className={getShoppingCartButtonClasses()}
        type='button'
        onClick={() => {
          if (isInCart) {
            removeItemFromCart(itemId, itemType, true).catch(() => {
              isInCart = true;
            });
          } else {
            addItemToCart({ itemId, itemType, collectibleItemId, itemName }, true).catch(() => {
              isInCart = false;
            });
          }
        }}>
        {isInCart
          ? catalogTranslations.actionRemoveFromCart()
          : catalogTranslations.actionAddToCart()}
      </button>
    </div>
  );
};

AddToCartButton.defaultProps = {
  collectibleItemId: null,
  buttonClass: ''
};

export default AddToCartButton;
