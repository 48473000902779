import catalogModule from '../catalogModule';

function sortFilter(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.sortFilterTemplate
  };
}

catalogModule.directive('sortFilter', sortFilter);

export default sortFilter;
