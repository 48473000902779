import catalogModule from '../catalogModule';

function catalogPage(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.catalogPageTemplate,
    link(scope, element) {
      element.ready(() => {
        scope.renderShoppingCartButton();
      });
    }
  };
}

catalogModule.directive('catalogPage', catalogPage);

export default catalogPage;
