import angular from 'angular';
import { uuidService } from 'core-utilities';
import { CurrentUser } from 'Roblox';
import catalogModule from '../catalogModule';

function itemCardController($log, $scope, catalogConstants, catalogService, $timeout) {
  'ngInject';

  $scope.hasSecondaryInfo = () => {
    return $scope.isCreatorNameShown() || ($scope.item.unitsAvailableForConsumption > 0 && false);
  };

  $scope.isCreatorNameShown = () => {
    return (
      $scope.item &&
      $scope.item.creatorTargetId !== catalogConstants.robloxSystemUserId &&
      $scope.item.creatorName
    );
  };

  $scope.isItemThumbnailLoading = () => {
    if ($scope.item.itemType === catalogConstants.itemTypes.bundle) {
      return !$scope.layout.isBundleThumbnailLoaded;
    }
    return !$scope.layout.isAssetThumbnailLoaded;
  };

  $scope.isItemThumbnailAvailable = () => {
    return $scope.item.thumbnail && $scope.item.thumbnail.imageUrl;
  };

  $scope.shouldShowPremiumIcon = () => {
    return (
      $scope.library.isPremiumIconOnItemTilesEnabled === true &&
      $scope.item.premiumPricing !== undefined
    );
  };

  $scope.getDisplayPrice = () => {
    if ($scope.library.isPremiumPriceOnItemTilesEnabled && $scope.item.premiumPricing) {
      return $scope.item.premiumPricing.premiumPriceInRobux;
    }

    if ($scope.item.lowestPrice) {
      return $scope.item.lowestPrice;
    }

    return $scope.item.price;
  };

  $scope.clickItemCard = e => {
    if (!$scope.item.detailsUrl) {
      e.preventDefault();
    }
  };

  $scope.showVerifiedBadge = function (item) {
    return item && item.creatorHasVerifiedBadge;
  };

  $scope.getLimited1Resellers = assetId => {
    catalogService.getResellerData(assetId).then(function success(result) {
      // eslint-disable-next-line prefer-destructuring
      $scope.limited1Reseller = result.data[0];
      $scope.lowestAvailablePrice = result.data[0].price;
    });
  };

  $scope.getCollectibleItemDetails = collectibleItemId => {
    catalogService.postMarketplaceItemDetails(collectibleItemId).then(function success(result) {
      // eslint-disable-next-line prefer-destructuring
      $scope.collectibleItemDetail = result[0];
      const useLowestReseller =
        $scope.collectibleItemDetail.unitsAvailableForConsumption === 0 ||
        ($scope.collectibleItemDetail.lowestResalePrice !== 0 &&
          $scope.collectibleItemDetail.lowestResalePrice < $scope.collectibleItemDetail.price) ||
        $scope.collectibleItemDetail.saleLocationType === 'ExperiencesDevApiOnly';
      if (useLowestReseller) {
        $scope.lowestAvailablePrice = $scope.collectibleItemDetail.lowestResalePrice;
      } else {
        $scope.lowestAvailablePrice = $scope.collectibleItemDetail.price;
      }
    });
  };

  $scope.onItemCardHover = item => {
    if (item.collectibleItemId && $scope.collectibleItemDetail === undefined) {
      $scope.timer = setTimeout(() => {
        $scope.getCollectibleItemDetails(item.collectibleItemId);
      }, 500);
    } else if ((item.isLimited || item.isLimitedUnique) && $scope.limited1Reseller === undefined) {
      $scope.timer = setTimeout(() => {
        $scope.getLimited1Resellers(item.id);
      }, 500);
    } else if (!$scope.lowestAvailablePrice) {
      $scope.lowestAvailablePrice = item.price;
    }
  };

  $scope.onItemCardHoverExit = item => {
    clearTimeout($scope.timer);
  };

  $scope.itemLoading = item => {
    if (item.isLimited || item.isLimitedUnique) {
      if ($scope.limited1Reseller === undefined) {
        return false;
      }
    }
    if (item.collectibleItemId) {
      if ($scope.collectibleItemDetail === undefined) {
        return false;
      }
    }
    return true;
  };

  $scope.itemAvailableToPurchase = item => {
    if (item.isLimited || item.isLimitedUnique) {
      if ($scope.limited1Reseller === undefined) {
        return false;
      }
    }
    if (item.collectibleItemId) {
      if ($scope.collectibleItemDetail === undefined) {
        return false;
      }
      if (
        $scope.collectibleItemDetail.unitsAvailableForConsumption === 0 &&
        $scope.collectibleItemDetail.hasResellers === false &&
        item.itemRestrictions.includes('Collectible')
      ) {
        return false;
      }
      const useLowestReseller =
        item.collectibleItemId !== undefined &&
        item.itemRestrictions.includes('Collectible') &&
        ($scope.collectibleItemDetail.unitsAvailableForConsumption === 0 ||
          ($scope.collectibleItemDetail.lowestResalePrice !== 0 &&
            $scope.collectibleItemDetail.lowestResalePrice < $scope.collectibleItemDetail.price) ||
          $scope.collectibleItemDetail.saleLocationType === 'ExperiencesDevApiOnly');
      if (
        !useLowestReseller &&
        $scope.collectibleItemDetail.saleLocationType === 'ExperiencesDevApiOnly'
      ) {
        return false;
      }
    }

    if (item.isOffSale) {
      return false;
    }
    return true;
  };

  $scope.purchaseItem = function (event, item) {
    const uuid = uuidService.generateRandomUuid();
    event.preventDefault();
    let detail = {
      identifier: 'direct-purchase',
      name: item.name,
      itemType: item.itemType,
      assetId: item.id,
      productId: item.productId,
      assetType: '',
      expectedCurrency: 1,
      expectedPrice: item.price,
      expectedPurchaserId: CurrentUser.id,
      expectedPurchaserType: 'User',
      refreshPage: false,
      expectedSellerName: item.creatorName,
      resalePurchase: false
    };
    if (item.isLimited || item.isLimitedUnique) {
      detail = {
        ...detail,
        expectedSellerId: $scope.limited1Reseller.seller.id,
        userAssetId: $scope.limited1Reseller.userAssetId
      };
      detail.expectedPrice = $scope.limited1Reseller.price;
      detail.expectedSellerName = $scope.limited1Reseller.seller.name;
      detail.resalePurchase = true;
    }

    if (item.collectibleItemId && $scope.collectibleItemDetail) {
      const useLowestReseller =
        (item.collectibleItemId !== undefined &&
          !item.itemRestrictions.includes('Collectible') &&
          $scope.collectibleItemDetail.unitsAvailableForConsumption === 0) ||
        ($scope.collectibleItemDetail.lowestResalePrice !== 0 &&
          ($scope.collectibleItemDetail.lowestResalePrice < $scope.collectibleItemDetail.price ||
            $scope.collectibleItemDetail.saleLocationType === 'ExperiencesDevApiOnly'));
      if (useLowestReseller) {
        detail = {
          ...detail,
          collectibleItemId: item.collectibleItemId,
          collectibleItemInstanceId:
            $scope.collectibleItemDetail.lowestAvailableResaleItemInstanceId,
          collectibleProductId: $scope.collectibleItemDetail.lowestAvailableResaleProductId,
          expectedSellerType: 'User',
          idempotencyKey: uuid
        };
        detail.expectedPrice = $scope.collectibleItemDetail.lowestResalePrice;
        detail.resalePurchase = true;
      } else {
        detail = {
          ...detail,
          collectibleItemId: item.collectibleItemId,
          collectibleProductId: $scope.collectibleItemDetail.collectibleProductId,
          expectedSellerType: 'User',
          idempotencyKey: uuid
        };
      }
    }
    window.dispatchEvent(
      new CustomEvent('angular-to-react-purchase-event', {
        detail
      })
    );
  };
}

catalogModule.controller('itemCardController', itemCardController);

export default itemCardController;
