import { TranslateFunction } from 'react-utilities';

export type TPriceDisplayMode =
  | 'MODERATED'
  | 'NOT_FOR_SALE'
  | 'PURCHASE_DISABLED'
  | 'PREMIUM_USER_PREMIUM_ITEM'
  | 'UNAUTHENTICATED_USER_PREMIUM_ITEM'
  | 'NON_PREMIUM_USER_PREMIUM_ITEM'
  | 'REGULAR'
  | 'ITEM_ALREADY_OWNED'
  | 'LIMITED_NO_RESELLERS'
  | 'LIMITED_WITH_RESELLERS'
  | 'LIMITED_WITH_CONSUMABLE'
  | 'COLLECTIBLE_NO_SELLERS'
  | 'COLLECTIBLE_ONLY_ORIGINAL'
  | 'COLLECTIBLE_ONLY_RESELLERS'
  | 'COLLECTIBLE_ONLY_RESELLERS_QUANTITY_LIMIT_REACHED'
  | 'COLLECTIBLE_IN_EXPERIENCE_ONLY_ORIGINAL';

export type TItemPricing = {
  priceDisplayMode: TPriceDisplayMode;
  price: number | null;
  premiumPrice?: number | null;
  premiumDiscountPercentage?: number | null;
  lowestPrice?: number | null;
  lowestResalePrice?: number | null;
  unitsAvailableForConsumption?: number | null;
  totalQuantity?: number | null;
  saleLocationType?: string | null;
};

export type TCategoryMapping = {
  categoryId: number;
  subcategoryId: number;
  category?: string;
  subcategory?: string;
};

export type TCategoryMappings = {
  typeToCategory: Record<string, number>;
  typeToSubcategory: Record<string, number>;
  categories: Record<string, number>;
  subcategories: Record<string, number>;
};

export type TCategoryTranslation = {
  category?: string | null;
  subcategory?: string | null;
  pregeneratedCategory?: string | null;
  categoryString?: string | null;
};

export type TTag = {
  tagId: string;
  name: string;
  localizedDisplayName: string;
};
export type TItemTag = {
  id: string;
  tag: TTag;
};

export type TItemDetailsCreatorType = 'Group' | 'User';

export type TItemType = 'Asset' | 'Bundle';

export type TBundledItem = {
  id: number;
  name: string;
  owned: boolean;
  type: TItemType;
};

export type TUserItemPermissions = {
  canConfigureItem: boolean;
  canManageItem: boolean;
  canReportItem: boolean;
  canSponsorItem: boolean;
  canViewConfigurePage: boolean;
  canViewDevelopPage: boolean;
  isAllowedInShowcase: boolean;
  isDeletableType: boolean;
  isInShowcase: boolean;
  isModerated: boolean;
  isNotCurrentlyForSale: boolean;
  isPurchaseEnabled: boolean;
};

export type TPremiumPricing = {
  premiumDiscountPercentage?: number;
  premiumPriceInRobux?: number;
};

export type TBundleItem = {
  id: number;
  type: string;
};

export type TExperience = {
  id: string;
  name: string;
  detailPageLink: string;
};

type TBaseItemDetails = {
  collectibleItemId?: string;
  collectibleProductId?: string;
  creatorHasVerifiedBadge: boolean;
  creatorId?: number;
  creatorName: string;
  creatorTargetId: number;
  creatorType: TItemDetailsCreatorType;
  description: string;
  expectedSellerId?: number;
  id: number;
  isPurchasable: boolean;
  itemRestrictions: string[];
  lowestPrice?: number;
  lowestResalePrice?: number;
  name: string;
  owned: boolean;
  premiumPricing?: TPremiumPricing;
  price: number;
  productId: number;
  totalQuantity?: number;
  unitsAvailableForConsumption?: number;
  saleLocationType?: string;
  isOffSale?: boolean;
  hasResellers?: boolean;
  quantityLimitPerUser?: number;
  resaleRestriction: number;
  experiences?: TExperience[];
  isLimited?: boolean;
  isLimitedUnique?: boolean;
  priceStatus?: string;
};

export type TAssetItemDetails = TBaseItemDetails & {
  assetType: number;
  itemStatus: string[];
  itemType: 'Asset';
};

export type TItemCardRestrictions = {
  isLimited: boolean;
  isRthro: boolean;
  isThirteenPlus: boolean;
  isLimitedUnique: boolean;
  itemRestrictionIcon: string;
  isCollectible: boolean;
  isDynamicHead: boolean;
};

export type TItemStatus = TItemStatusIcon | TItemStatusLabel;

export type TItemStatusIcon = {
  isIcon: boolean;
  type: string;
};

export type TItemStatusLabel = {
  class: string;
  label: string;
};

export function isItemStatusIcon(status: TItemStatus): status is TItemStatusIcon {
  return (status as TItemStatusIcon).isIcon !== undefined;
}

export type TBundleItemDetails = TBaseItemDetails & {
  bundledItems: TBundledItem[];
  bundleType: number;
  items: TBundleItem[];
  itemType: 'Bundle';
};

export type TOwnedItemInstance = {
  id?: number;
  instanceId?: number;
  name?: string;
  type?: TItemType;
  collectibleItemInstance?: TL2ResellableCopy;
};

export type TResellerItem = {
  userAssetId: number;
  seller: {
    hasVerifiedBadge: boolean;
    id: number;
    type: TItemDetailsCreatorType;
    name: string;
  };
  price: number;
  serialNumber?: number;
};

export type TCollectibleResellerItem = {
  userAssetId: undefined;
  collectibleItemInstanceId: string;
  collectibleProductId: string;
  seller: {
    hasVerifiedBadge: boolean;
    name: string;
    sellerId: number;
    sellerType: TItemDetailsCreatorType;
  };
  price: number;
  serialNumber?: number;
};

export type TItemPurchaseParams = {
  translate: TranslateFunction;
  productId: number;
  isRentable?: boolean;
  expectedCurrency: number;
  expectedPrice: number;
  thumbnail: React.ReactNode;
  assetName: string;
  assetType: string;
  assetTypeDisplayName?: string;
  expectedSellerId: number;
  sellerName: string;
  isPlace?: boolean;
  isPrivateServer?: boolean;
  expectedPromoId?: number;
  userAssetId?: number;
  showSuccessBanner?: boolean;
  handlePurchase?: () => void;
  onPurchaseSuccess?: () => void;
  customProps?: () => void;
  collectibleItemId?: string | null;
  collectibleItemInstanceId?: string | null;
  collectibleProductId?: string | null;
  sellerType?: string | null;
};

export type TL2ResellableCopies = {
  itemInstances: Array<TL2ResellableCopy>;
};
export type TL2ResellableCopy = {
  collectibleItemId: string;
  collectibleInstanceId: string;
  collectibleProductId: string;
  serialNumber: number;
  price: number;
  isHeld: boolean;
  saleState: string;
};
