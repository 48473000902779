import catalogModule from '../catalogModule';

function salesTypeFilter(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.salesTypeFilterTemplate
  };
}

catalogModule.directive('salesTypeFilter', salesTypeFilter);

export default salesTypeFilter;
