import catalogModule from '../catalogModule';

function creatorFilters(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.creatorFiltersTemplate
  };
}

catalogModule.directive('creatorFilters', creatorFilters);

export default creatorFilters;
