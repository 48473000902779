import { EnvironmentUrls, Endpoints, DisplayNames, CurrentUser } from 'Roblox';
import { concatTexts } from 'core-utilities';
import { TranslateFunction } from 'react-utilities';
import { AxiosError } from 'axios';
import catalogConstants from '../constants/catalogConstants';
import {
  TAssetItemDetails,
  TBundleItemDetails,
  TItemCardRestrictions,
  TItemStatus,
  isItemStatusIcon
} from '../../itemDetailsInfo/constants/types';
import {
  AssetsCollection,
  Category,
  CategoryEnumLibrary,
  ItemWithAllDetails,
  ItemWithDetails,
  ModifiedQuery,
  Query,
  TGenericItemDetails,
  TItem,
  Topic
} from '../constants/types';
import { QueryParamKeys, QueryParams, QueryParamsKey } from '../constants/queryParams.types';
import ItemStatusConfigList from '../constants/itemStatusConfig';

class UtilityService {
  static buildItemDetailsUrl(item: ItemWithDetails): string {
    const { itemType, id } = item;
    const { itemTypes } = catalogConstants;

    switch (itemType) {
      case itemTypes.bundle:
        return Endpoints.getAbsoluteUrl
          ? Endpoints.getAbsoluteUrl(`/bundles/${id}/`)
          : `${EnvironmentUrls.websiteUrl}/bundles/${id}/`;
      case itemTypes.asset:
      default:
        return Endpoints.getAbsoluteUrl
          ? Endpoints.getAbsoluteUrl(`/catalog/${id}/`)
          : `${EnvironmentUrls.websiteUrl}/catalog/${id}/`;
    }
  }

  static getNameForDisplay(item: ItemWithDetails): string {
    const { userTypes, robloxSystemUserId } = catalogConstants;
    const { creatorName, creatorType, creatorTargetId } = item;
    return userTypes.user === creatorType &&
      robloxSystemUserId !== creatorTargetId &&
      DisplayNames.Enabled()
      ? concatTexts.concat(['', creatorName])
      : creatorName;
  }

  static getQueriesValueIntoInt(searchParams: URLSearchParams): QueryParams {
    const queries: QueryParams = {};

    searchParams.forEach((value, key) => {
      const valueAsNumber = parseInt(value, 10);
      if (key === 'Category') {
        queries.Category = valueAsNumber;
      } else if (key === 'Subcategory') {
        queries.Subcategory = valueAsNumber;
      } else if (key === 'SortType') {
        queries.SortType = valueAsNumber;
      } else if (key === 'SortAggregation') {
        queries.SortAggregation = valueAsNumber;
      } else if (key === 'CurrencyType') {
        queries.CurrencyType = valueAsNumber;
      } else if (key === 'Gears') {
        queries.Gears = valueAsNumber;
      } else if (key === 'CreatorID') {
        queries.CreatorID = valueAsNumber;
      } else if (key === 'pxMin') {
        queries.pxMin = valueAsNumber < 0 ? 0 : valueAsNumber;
      } else if (key === 'pxMax') {
        queries.pxMax = valueAsNumber < 0 ? 0 : valueAsNumber;
      } else if (key === 'salesTypeFilter') {
        queries.salesTypeFilter = valueAsNumber;
      } else if (key === 'IncludeNotForSale') {
        if (value !== 'false') {
          queries.IncludeNotForSale = true;
        }
      } else if (key === 'Keyword') {
        queries.Keyword = value;
      } else if (key === 'topics') {
        queries.topics = value;
      } else if (key === 'CreatorType') {
        queries.CreatorType = value;
      } else if (key === 'CreatorName') {
        queries.CreatorName = value;
      } else if (key === 'TriggeredByTopicDiscovery') {
        queries.TriggeredByTopicDiscovery = value === 'true';
      }
    });

    return queries;
  }

  static getCatalogContentKey(item: TItem): string {
    const { id, itemType } = item;
    return `${itemType}_${id}`;
  }

  static updateSearchItemDetails(
    updatedDetails: ItemWithDetails[],
    searchResultDict: AssetsCollection
  ): void {
    if (updatedDetails) {
      updatedDetails.forEach(item => {
        const { key } = item;
        const itemWithDetails: ItemWithAllDetails = {
          ...item,
          detailsUrl: UtilityService.buildItemDetailsUrl(item),
          nameForDisplay: UtilityService.getNameForDisplay(item),
          detailsLoaded: true
        };

        Object.assign(searchResultDict[key], itemWithDetails);
      });
    }
  }

  static formatQueries(queryParams: QueryParams, cursor?: string | undefined): Query {
    const formatQueries: Query = {};
    Object.entries(queryParams).forEach(([keyString, value]) => {
      const key = keyString as QueryParamsKey;
      if (key === 'Category') {
        formatQueries.category = value as number;
      } else if (key === 'Subcategory') {
        formatQueries.subcategory = value as number;
      } else if (key === 'Gears') {
        formatQueries.subcategory = value as number;
      } else if (key === 'topics') {
        formatQueries.topics = value as string;
      } else if (key === 'SortType') {
        formatQueries.sortType = value as number;
      } else if (key === 'SortAggregation') {
        formatQueries.sortAggregation = value as number;
      } else if (key === 'Keyword') {
        formatQueries.keyword = value as string;
      } else if (key === 'pxMin') {
        formatQueries.minPrice = value as number;
      } else if (key === 'pxMax') {
        formatQueries.maxPrice = value as number;
      } else if (key === 'TriggeredByTopicDiscovery') {
        formatQueries.TriggeredByTopicDiscovery = value as boolean;
      } else if (key === 'CreatorID') {
        formatQueries.creatorTargetId = value as number | 'custom';
      } else if (key === 'CreatorType') {
        formatQueries.creatorType = value as string;
      } else if (key === 'CreatorName') {
        formatQueries.creatorName = value as string;
      } else if (key === 'IncludeNotForSale') {
        formatQueries.includeNotForSale = value as boolean;
      } else if (key === 'salesTypeFilter') {
        formatQueries.salesTypeFilter = value as number;
      }
    });

    if (cursor) {
      formatQueries.cursor = cursor;
    }

    return formatQueries;
  }

  static appendQueryParamsToUrl(
    queryParams: QueryParams,
    setCurrentUrl: (newUrl: string) => void
  ): void {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    // First, delete all existing keys from the URLSearchParams if they exist in the QueryParams type
    QueryParamKeys.forEach(key => {
      if (params.has(key)) {
        params.delete(key);
      }
    });

    // Then, add new parameters from queryParams
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (value === true) {
          // Skip boolean true values - we'll handle them separately
        } else {
          params.set(key, value.toString());
        }
      }
    });

    let queryString = params.toString();

    // Handle boolean true values
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value === true) {
        queryString += `&${key}`;
      }
    });

    // Construct the new URL with query parameters
    const newUrl = `${url.origin}${url.pathname}?${queryString}`;

    const currentUrl = window.location.href;

    if (newUrl !== currentUrl) {
      // Use history.pushState to update the URL without reloading the page
      window.history.pushState({ path: newUrl }, '', newUrl);

      setCurrentUrl(newUrl);
    }
  }

  static mapItemRestrictionIcons(item: TGenericItemDetails): TItemCardRestrictions {
    // Initialize the restrictions object with default values
    const restrictions: TItemCardRestrictions = {
      isLimited: false,
      isRthro: false,
      isThirteenPlus: false,
      isLimitedUnique: false,
      itemRestrictionIcon: '',
      isCollectible: false,
      isDynamicHead: false
    };

    if (item && item.itemRestrictions) {
      const { itemTypes, itemRestrictionTypes, itemRestrictionIcons } = catalogConstants;
      const { itemRestrictions } = item;

      // Check for each restriction and update the restrictions object accordingly
      restrictions.isLimited = itemRestrictions.includes(itemRestrictionTypes.limited);
      restrictions.isCollectible = itemRestrictions.includes(itemRestrictionTypes.collectible);
      restrictions.isRthro = itemRestrictions.includes(itemRestrictionTypes.rthro);
      restrictions.isDynamicHead = itemRestrictions.includes(itemRestrictionTypes.dynamicHead);
      restrictions.isThirteenPlus = itemRestrictions.includes(itemRestrictionTypes.thirteenPlus);
      restrictions.isLimitedUnique = itemRestrictions.includes(itemRestrictionTypes.limitedUnique);

      // Determine the appropriate icon
      if (item.itemType === itemTypes.bundle) {
        if (restrictions.isLimited) {
          restrictions.itemRestrictionIcon = restrictions.isRthro
            ? itemRestrictionIcons.rthroLimitedLabel
            : itemRestrictionIcons.rthroLabel;
        } else if (restrictions.isDynamicHead) {
          restrictions.itemRestrictionIcon = itemRestrictionIcons.dynamicHead;
        } else if (restrictions.isCollectible) {
          restrictions.itemRestrictionIcon = itemRestrictionIcons.collectible;
        }
      } else if (restrictions.isLimitedUnique) {
        restrictions.itemRestrictionIcon = restrictions.isThirteenPlus
          ? itemRestrictionIcons.thirteenPlusLimitedUnique
          : itemRestrictionIcons.limitedUnique;
      } else if (restrictions.isLimited) {
        restrictions.itemRestrictionIcon = restrictions.isThirteenPlus
          ? itemRestrictionIcons.thirteenPlusLimited
          : itemRestrictionIcons.limited;
      } else if (restrictions.isThirteenPlus) {
        restrictions.itemRestrictionIcon = itemRestrictionIcons.thirteenPlus;
      } else if (restrictions.isDynamicHead) {
        restrictions.itemRestrictionIcon = itemRestrictionIcons.dynamicHead;
      } else if (restrictions.isCollectible) {
        restrictions.itemRestrictionIcon = itemRestrictionIcons.collectible;
      }
    }

    return restrictions;
  }

  static mapItemStatusIconsAndLabels(
    item: (TAssetItemDetails | TBundleItemDetails) & TItemCardRestrictions,
    translate: TranslateFunction
  ): TItemStatus[] | undefined {
    if (item && (item as TAssetItemDetails).itemStatus) {
      const itemStatusIconsAndLabels: TItemStatus[] = [];
      (item as TAssetItemDetails).itemStatus.forEach(status => {
        const itemStatusConfig = ItemStatusConfigList[status];
        if (itemStatusConfig) {
          if (isItemStatusIcon(itemStatusConfig)) {
            itemStatusIconsAndLabels.push(itemStatusConfig);
          } else {
            itemStatusIconsAndLabels.push({
              class: itemStatusConfig.class,
              label: translate(itemStatusConfig.label)
            });
          }
        } else {
          // TODO demelianov - log error for missing item status config
        }
      });
      return itemStatusIconsAndLabels;
    }

    return undefined;
  }

  static translateToEnumStrings(
    categoryEnumLibrary: CategoryEnumLibrary | undefined,
    queries: Query
  ): ModifiedQuery {
    let translatedEnums: {
      category: string | null;
      subcategory: string | null;
    } = { category: null, subcategory: null };

    const { category, subcategory, gears } = queries;
    if (category && categoryEnumLibrary && categoryEnumLibrary[category]) {
      const { categoryEnum, subcategoryEnums } = categoryEnumLibrary[category];
      translatedEnums = {
        ...translatedEnums,
        category: categoryEnum
      };

      if (subcategoryEnums) {
        let subcategoryString: string | null = null;
        if (gears) {
          subcategoryString = subcategoryEnums[gears];
        } else if (subcategory) {
          subcategoryString = subcategoryEnums[subcategory];
        }

        if (subcategoryString) {
          translatedEnums = {
            ...translatedEnums,
            subcategory: subcategoryString
          };
        }
      }
    }

    return {
      ...queries,
      ...translatedEnums
    };
  }

  static buildUserLink(item: Pick<ItemWithDetails, 'creatorType' | 'creatorTargetId'>): string {
    const { creatorType, creatorTargetId } = item || {};
    const { userTypes } = catalogConstants;
    switch (creatorType) {
      case userTypes.group:
        return Endpoints.getAbsoluteUrl(`/groups/${creatorTargetId}`);
      case userTypes.user:
      default:
        return Endpoints.getAbsoluteUrl(`/users/${creatorTargetId}/profile`);
    }
  }

  static formatTopic = (topicName: string): string => {
    return topicName.toLowerCase();
  };

  static buildTopicKeyword = (topicsToBuild: Topic[], separator: string): string => {
    let keyword = '';
    topicsToBuild.forEach(topic => {
      if (keyword.length > 0) {
        keyword += separator;
      }
      keyword += UtilityService.formatTopic(topic.displayName);
    });

    return keyword;
  };

  static getCategoryMenu = (
    categories: Category[] | undefined,
    categoryId: number | undefined
  ): Category | undefined => {
    if (categoryId === undefined) {
      return undefined;
    }

    return categories?.find(c => c.categoryId === categoryId);
  };

  static isKeywordResultCensored = (keyword: string): boolean => {
    return keyword === catalogConstants.keywordSearch.censoredKey;
  };

  static buildErrorMessages = (errors: AxiosError[], userAgent: string): string => {
    let errorMessage = `userId-${CurrentUser.userId}`;
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        const { code, message } = error;
        errorMessage += `-code-${code || ''}-message-${message}`;
      });
    }
    errorMessage += `-userAgent-${userAgent}`;
    return errorMessage;
  };
}

export default UtilityService;
