import catalogModule from '../catalogModule';

function itemCard(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.cardItemTemplate
  };
}

catalogModule.directive('itemCard', itemCard);

export default itemCard;
