import catalogModule from '../catalogModule';

const menuConstants = {
  categoryTypes: {
    Featured: 0,
    All: 1,
    Collectibles: 2,
    Clothing: 3,
    BodyParts: 4,
    Gear: 5,
    Models: 6,
    Plugins: 7,
    Decals: 8,
    Audio: 9,
    Meshes: 10,
    Accessories: 11,
    AvatarAnimations: 12,
    CommunityCreations: 13,
    Recommended: 15 // Special category that does not map to any assetTypes in the backend
  },
  defaultGearSubcategoryId: 0,
  mappingToApiParameters: {
    category: 'category',
    subcategory: 'subcategory',
    gears: 'subcategory', // transition from old endpoint to new one
    sortaggregation: 'sortAggregation',
    sortcurrency: 'sortCurrency',
    sorttype: 'sortType',
    creatortype: 'creatorType',
    creatorid: 'creatorTargetId',
    creatorname: 'creatorName',
    pxmin: 'minPrice',
    pxmax: 'maxPrice',
    keyword: 'keyword',
    includenotforsale: 'includeNotForSale',
    triggeredbytopicdiscovery: 'TriggeredByTopicDiscovery',
    topics: 'topics',
    salestypefilter: 'salesTypeFilter'
  }
};

catalogModule.constant('menuConstants', menuConstants);

export default menuConstants;
