import { EnvironmentUrls } from 'Roblox';

const { apiGatewayUrl } = EnvironmentUrls;

const url = {
  getExperimentationValues: (projectId, layerName, values) => ({
    url: `${apiGatewayUrl}/product-experimentation-platform/v1/projects/${projectId}/layers/${layerName}/values?parameters=${values.join(
      ','
    )}`,
    withCredentials: true
  })
};

// We are using the mobile layer in order to allow this test to take place during the mobile test (better data)
const layerNames = {
  avatarShopPage: 'AvatarMarketplace.UI',
  avatarShopRecommendationsAndSearchWeb: 'AvatarMarketplace.RecommendationsAndSearch.Web',
  avatarShopRecommendationsAndSearch: 'AvatarMarketplace.RecommendationsAndSearch',
  avatarMarketplaceGuidedFeatures: 'AvatarMarketplace.GuidedFeatures',
  avatarMarketplaceShoppingCart: 'AvatarMarketplace.ShoppingCart',
  avatarMarketplaceSorts: 'AvatarMarketplace.Sorts'
};

const parameterNames = {
  avatarShopPage: ['avatarShopPlacement', 'isItemDetailsEnabled'],
  avatarShopRecommendationsAndSearchWeb: [],
  avatarShopRecommendationsAndSearch: ['infiniteScroll'],
  avatarMarketplaceGuidedFeatures: ['topicsEnabledWeb'],
  avatarMarketplaceShoppingCart: [],
  avatarMarketplaceSorts: []
};

const defaultProjectId = 1;

export default {
  url,
  parameterNames,
  layerNames,
  defaultProjectId
};
