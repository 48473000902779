import { BootstrapWidgets } from 'Roblox';
import catalogModule from '../catalogModule';

function accordionMenu() {
  'ngInject';

  return {
    restrict: 'A',
    scope: true,
    link: () => {
      BootstrapWidgets.SetupAccordion();
    }
  };
}

catalogModule.directive('accordionMenu', accordionMenu);

export default accordionMenu;
