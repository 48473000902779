import { TItemStatus } from '../../itemDetailsInfo/constants/types';

const ItemStatusConfigList: Record<string, TItemStatus> = {
  New: {
    class: 'status-new',
    label: 'Label.New'
  },
  Sale: {
    class: 'status-sale',
    label: 'Label.Sale'
  },
  XboxExclusive: {
    class: 'status-default has-text',
    label: 'Label.Xbox'
  },
  AmazonExclusive: {
    class: 'status-default has-text',
    label: 'Label.Amazon'
  },
  GooglePlayExclusive: {
    class: 'status-default has-text',
    label: 'Label.GoogleOnly'
  },
  IosExclusive: {
    class: 'status-default has-text',
    label: 'Label.AppleOnly'
  },
  SaleTimer: {
    isIcon: true,
    type: 'icon-clock'
  }
};

export default ItemStatusConfigList;
