import { AxiosPromise, httpService } from 'core-utilities';
import catalogConstants from '../constants/catalogConstants';

export type VngBuyRobuxPolicyResponse = {
  shouldShowVng: boolean;
};

export type AppPolicyBehaviorResponse = {
  EnableContinuousLoad: boolean;
};

class UniversalAppConfigurationService {
  static getVngBuyRobuxPolicy(): AxiosPromise<VngBuyRobuxPolicyResponse> {
    return httpService.get<VngBuyRobuxPolicyResponse>(
      catalogConstants.endpoints.getVngBuyRobuxBehavior
    );
  }

  static getAppPolicy(): AxiosPromise<AppPolicyBehaviorResponse> {
    return httpService.get<AppPolicyBehaviorResponse>(
      catalogConstants.endpoints.getAppPolicyBehavior
    );
  }
}

export default UniversalAppConfigurationService;
