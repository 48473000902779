/**
 * @fileoverview ErrorBoundary.tsx
 * This higher order component is used to catch errors that occur in the children components and report them to AX.
 * The error message is converted to lowercase and spaces are replaced with underscores.
 * The component name is extracted from the component stack and the error message is appended to it.
 * The error is then reported to reportAXError.
 * The data is sent to counters + superset, under table webfrontend_userjourney_actions
 */
import React, { ReactNode } from 'react';
import { AXAnalyticsService } from 'Roblox';

interface ErrorBoundaryProps {
  children: ReactNode;
  containerName: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { containerName } = this.props;

    // Extract the component name from the component stack
    const componentStack = errorInfo.componentStack?.split('\n')[1]?.trim() || 'Unknown Component';
    const componentNameMatch = /at (\w+)/.exec(componentStack);
    const componentName = componentNameMatch ? componentNameMatch[1] : 'UnknownComponent';

    // Convert the error message to lowercase and replace spaces with underscores
    const errorMessage = error.message.toLowerCase().replace(/\s+/g, '_').slice(0, 30);

    // Create the item name
    const itemName = `${componentName}_${errorMessage}`;

    // Create the log
    const log = JSON.stringify({
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack
    });

    // Report the erro
    // We need to specify container name as counters name so we can build the graphs on grafana
    AXAnalyticsService.reportAXError({ itemName, counterName: containerName, log });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
