import React from 'react';
import { render } from 'react-dom';
import ShoppingCartButton from '../components/ShoppingCartButton';

/** *****************************************************************************
 NOTE:
   These mounting functions should only be used if you aren't using React.
   Otherwise, you should render the ShoppingCartButton directly within your app.
****************************************************************************** */

/*
Mounts the button inside an element by ID,
and returns a true if mounted or was already mounted.
*/
function mountCartButton(entryId: string): boolean {
  const entryElement = document.getElementById(entryId);

  if (entryElement) {
    if (entryElement.querySelector('.shopping-cart-btn-container')) {
      return true;
    }
    render(React.createElement(ShoppingCartButton, null), entryElement);
    return true;
  }
  return false;
}

const wait = (time = 100) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(undefined);
    }, time);
  });
};

/*
Attempts to mount the the cart button every N milliseconds until a max
attempt count is reached.
*/
export const tryMountCartButton = async (entryId: string, attempts = 0): Promise<boolean> => {
  if (!mountCartButton(entryId)) {
    if (attempts >= 20) {
      return false;
    }
    await wait(100);
    return tryMountCartButton(entryId, attempts + 1);
  }
  return true;
};

export default tryMountCartButton;
