import catalogModule from '../catalogModule';

function shimmerMenu(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.shimmerMenu
  };
}

catalogModule.directive('shimmerMenu', shimmerMenu);

export default shimmerMenu;
