import React from 'react';
import { render } from 'react-dom';
import '../../../css/catalog/catalog.scss';
import { BrowserRouter } from 'react-router-dom';
import CatalogPageContainer from './containers/CatalogPageContainer';

const ENTRY_ID = 'catalog-react-container';

function renderCatalogContainer(): void {
  const containerElement = document.getElementById(ENTRY_ID);
  if (containerElement) {
    render(
      <BrowserRouter>
        <CatalogPageContainer />
      </BrowserRouter>,
      containerElement
    );
  }
}

window.addEventListener('load', renderCatalogContainer);
