import catalogModule from '../catalogModule';
import menuConstants from '../constants/menuConstants';
import { createCartService } from '../../../react/shoppingCart/services/cartService';
import { addItemAction, removeItemAction } from '../../../react/shoppingCart/utils/actions';

const cartService = createCartService();

function itemsContainerController($log, $scope, catalogConstants, $anchorScroll, $timeout) {
  'ngInject';

  $scope.getNumberItemToDisplay = () => {
    if (catalogConstants.expandedCategoryList.includes($scope.queries.category)) {
      return catalogConstants.numberOfSearchItemsExpanded;
    }
    if ($scope.isInfiniteScrollEnabled() && $scope.isInfiniteScrollWebEnabled()) {
      return catalogConstants.numberOfSearchItemsExpanded;
    }
    if ($scope.library.isFullScreen) {
      return catalogConstants.numberOfSearchItemsForFullScreen;
    }
    const { isSplashPage } = $scope.library;
    return isSplashPage ? catalogConstants.numberOfSearchItems : $scope.searchResultList.length;
  };

  $scope.isInfiniteScrollEnabled = () => {
    const { initialized, isPhone } = $scope.library;
    return (initialized && isPhone) || $scope.isInfiniteScrollWebEnabled();
  };

  $scope.isRegularItemsResultAvailable = () => {
    const { initialized, isPhone } = $scope.library;
    return initialized && !isPhone;
  };

  $scope.isBreadcrumbsAvailable = () => {
    const { initialized, isPhone } = $scope.library;
    return initialized && !isPhone;
  };

  $scope.shouldShowHeading = () => {
    return $scope.layout.isNavigationMenuLoaded && $scope.isCurrentCategoryRecommended();
  };

  $scope.isRecommendedEnabled = () => {
    return $scope.library.defaultCategoryId === menuConstants.categoryTypes.Recommended;
  };

  $scope.showRecommendedHeading = () => {
    return (
      ($scope.library.isSplashPage && $scope.isRecommendedEnabled()) ||
      $scope.isCurrentCategoryRecommended()
    );
  };

  $scope.showReturnToTop = () => {
    return $scope.searchResultList.length > catalogConstants.numberOfSearchItemsExpanded;
  };

  $scope.returnToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  $scope.pager = {
    loadNextPage() {
      const { nextPageCursor, startPaging } = $scope.paginations;
      if (nextPageCursor && !startPaging) {
        $scope.queries.cursor = nextPageCursor;
        $scope.getSearchItems($scope.queries, true);
        $scope.paginations.direction = catalogConstants.pageDirection.next;
        $scope.paginations.startPaging = true;
      }
    },
    loadPreviousPage() {
      const { previousPageCursor, startPaging } = $scope.paginations;
      if (previousPageCursor && !startPaging) {
        $scope.queries.cursor = previousPageCursor;
        $scope.getSearchItems($scope.queries, true);
        $scope.paginations.direction = catalogConstants.pageDirection.prev;
        $scope.paginations.startPaging = true;
      }
    },
    canLoadNextPage() {
      const { nextPageCursor, startPaging } = $scope.paginations;
      return nextPageCursor && !startPaging;
    },
    canLoadPreviousPage() {
      const { previousPageCursor, startPaging } = $scope.paginations;
      return previousPageCursor && !startPaging;
    },
    getCurrentPageNumber() {
      return $scope.paginations.currentPage;
    }
  };

  $scope.shoppingCartItemsByItemId = null;

  $scope.handleNewCartState = cartState => {
    if (cartState?.items) {
      const itemsByItemId = {};
      for (const i of cartState.items) {
        itemsByItemId[i.itemId] = i;
      }
      $scope.shoppingCartItemsByItemId = itemsByItemId;
      setTimeout(() => {
        $scope?.$apply?.();
      }, 10);
    }
  };

  $scope.loadCartState = async () => {
    const cartState = await cartService.getCartState();
    if (cartState) {
      $scope.handleNewCartState(cartState);
    }
  };

  $scope.isItemInCart = item => {
    return !!$scope?.shoppingCartItemsByItemId?.[item.id];
  };

  $scope.addToCart = (evt, item) => {
    evt?.preventDefault?.();
    evt?.stopPropagation?.();
    evt?.stopImmediatePropagation?.();
    cartService.dispatch(addItemAction(item, true));
  };

  $scope.removeFromCart = (evt, item) => {
    evt?.preventDefault?.();
    evt?.stopPropagation?.();
    evt?.stopImmediatePropagation?.();

    cartService.dispatch(removeItemAction(item, true));
  };

  $scope.initializeShoppingCart = () => {
    $scope.loadCartState();
    cartService.onCartStateChange(cartState => {
      if (cartState) {
        $scope.handleNewCartState(cartState);
      }
    });
  };

  $scope.initializeShoppingCart();
}

catalogModule.controller('itemsContainerController', itemsContainerController);

export default itemsContainerController;
