import { CurrentUser, TDetailEntry, EnvironmentUrls } from 'Roblox';
import { localStorageService } from 'core-roblox-utilities';
import { seoName } from 'core-utilities';
import { TCartState, TCartItem, TItemDetails, TUnsanitizedItemDetails } from '../constants/types';
import { urlConfigs, itemTypes } from '../constants/shoppingCartConstants';

export const rand = (() => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return (size = 12) => {
    let randomChars = '';

    for (let i = 0; i < size; i++)
      randomChars += chars.charAt(Math.floor(Math.random() * chars.length));

    return randomChars;
  };
})();

function getCartStorageKey() {
  const userId = CurrentUser.isAuthenticated && CurrentUser.userId;
  return `Roblox.AvatarMarketplace.Cart:${userId || ''}`;
}

export function fetchCartState(): TCartState {
  const key = getCartStorageKey();
  return {
    totalPrice: null,
    currentUserBalance: null,
    items: [],
    selectedItems: {},
    resellers: {},
    purchaseParams: {},
    itemDetails: {},
    ...localStorageService.getLocalStorage(key)
  } as TCartState;
}

export function setCartState(obj: TCartState): void {
  const key = getCartStorageKey();
  localStorageService.setLocalStorage(key, obj);
}

export function getItemPrice(itemDetail: TItemDetails): number {
  let expectedPrice = itemDetail.lowestPrice;
  if (
    itemDetail.collectibleItemId !== undefined &&
    itemDetail.collectibleItemDetails.saleLocationType === 'ExperiencesDevApiOnly'
  ) {
    expectedPrice = itemDetail.collectibleItemDetails.lowestResalePrice;
  } else if (
    CurrentUser.isPremiumUser &&
    itemDetail.premiumPricing !== undefined &&
    itemDetail.premiumPricing?.premiumPriceInRobux !== undefined
  ) {
    expectedPrice = itemDetail.premiumPricing?.premiumPriceInRobux;
  } else if (expectedPrice === undefined) {
    expectedPrice = itemDetail.price;
  }
  return expectedPrice || 0;
}

export function recalculateCartTotal(cartState: TCartState): TCartState {
  const { items, itemDetails } = cartState;
  const totalPrice = items.reduce((acc, item) => {
    const itemDetail = itemDetails[item.itemId];
    if (itemDetail === undefined) {
      return acc;
    }
    let expectedPrice = getItemPrice(itemDetail);
    if (expectedPrice < 0) {
      expectedPrice = 0;
    }
    return acc + (expectedPrice || 0);
  }, 0);
  return { ...cartState, totalPrice };
}

export function recalculateCartTotalFromSelectedItems(
  cartState: TCartState,
  items: TCartItem[]
): number {
  const { itemDetails } = cartState;
  const totalPrice = items.reduce((acc, item) => {
    const itemDetail = itemDetails[item.itemId];
    if (itemDetail === undefined) {
      return acc;
    }
    let expectedPrice = getItemPrice(itemDetails[item.itemId]);
    if (expectedPrice < 0) {
      expectedPrice = 0;
    }
    return acc + (expectedPrice || 0);
  }, 0);
  return totalPrice;
}

export const isBundle = (item: TCartItem): boolean => item.itemType?.toLowerCase() === 'bundle';

export function standardizeCartItem(item: TUnsanitizedItemDetails): TCartItem {
  return {
    ...item,
    itemId: item.itemId || item.id || item.itemTargetId,
    itemName: item.name ?? item.itemName
  };
}

export function standardizeItemDetails(item: TUnsanitizedItemDetails): TItemDetails {
  return {
    ...item,
    id: item.itemId || item.id || item.itemTargetId
  };
}

export function standardizeItemDetailsFromHydratedItemDetails(item: TDetailEntry): TItemDetails {
  return {
    ...item,
    creatorType: item.creatorType === 'Group' ? 'Group' : 'User',
    itemType: item.itemType === 'Bundle' ? 'Bundle' : 'Asset',
    price: item.price === undefined ? -1 : item.price,
    bundledItems: []
  };
}

// splits an array of items into 2 arrays, grouped by whether it is a collectible item or not
export function splitItemsByCollectible(items: TCartItem[] = []): TCartItem[][] {
  const itemTypeCategorizer = ([legacyItems, collectibleItems]: TCartItem[][], item: TCartItem) =>
    item.collectibleItemId
      ? [legacyItems, [...collectibleItems, item]]
      : [[...legacyItems, item], collectibleItems];

  return items.reduce(itemTypeCategorizer, [[], []]);
}

type TExtractedLimited2Item = TCartItem & {
  collectibleItemId: string;
};

type TExtractedLimiteds = {
  limited1: TCartItem[];
  limited2: TExtractedLimited2Item[];
};
export function extractLimiteds(cartState: TCartState): TExtractedLimiteds {
  const { items, itemDetails } = cartState;
  const itemTypeCategorizer = ({ limited1, limited2 }: TExtractedLimiteds, item: TCartItem) => {
    const details = itemDetails[item.itemId];
    const collectibleItemId = item.collectibleItemId || details.collectibleItemId;
    // console.log('  -> checking item:', item.itemId, item.itemName);
    if (collectibleItemId) {
      // console.log('    -> item is collectible!!!');
      const l2Item: TExtractedLimited2Item = { ...item, collectibleItemId };
      return { limited1, limited2: [...limited2, l2Item] };
    }
    if (
      details?.itemRestrictions.includes('Limited') ||
      details?.itemRestrictions.includes('LimitedUnique')
    ) {
      // console.log('    -> item is limited 1.0!!!');
      return { limited1: [...limited1, item], limited2 };
    }
    // console.log('    -> item is normal...');
    return { limited1, limited2 };
  };

  return items.reduce(itemTypeCategorizer, { limited1: [], limited2: [] });
}

function doesDomElementContainQuerySelector(target: Element, selector: string): boolean {
  const elms = document.querySelectorAll(selector);
  if (elms?.length) {
    for (let i = 0; i < elms.length; i++) {
      const elm = elms[i];
      if (elm.contains?.(target)) {
        return true;
      }
    }
  }
  return false;
}

export function isDomElementInShoppingCart(target: Element): boolean {
  return (
    // did click cart btn
    doesDomElementContainQuerySelector(target, '.shopping-cart-btn') ||
    // did click modal backdrop
    doesDomElementContainQuerySelector(target, '.modal-backdrop.in') ||
    // did click modal
    doesDomElementContainQuerySelector(target, '.in.modal') ||
    // did click add remove btn
    doesDomElementContainQuerySelector(target, '.add-to-cart-btn-container')
  );
}

export const checkIfBundle = (itemType: string): boolean => {
  const simplifiedType = itemType.toLowerCase();

  return simplifiedType.includes(itemTypes.bundle);
};

export const getItemLink = (itemId: number, itemName: string, itemType: string): string => {
  let urlType = urlConfigs.assetRootUrlTemplate;
  if (checkIfBundle(itemType)) {
    urlType = urlConfigs.bundleRootUrlTemplate;
  }
  return `${EnvironmentUrls.websiteUrl}/${urlType}/${itemId}/${seoName.formatSeoName(itemName)}`;
};
