import catalogModule from '../catalogModule';

function mobileSearchOptions(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.mobileSearchOptionsTemplate
  };
}

catalogModule.directive('mobileSearchOptions', mobileSearchOptions);

export default mobileSearchOptions;
