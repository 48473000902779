import { httpService } from 'core-utilities';
import { ExperimentationService } from 'Roblox';
import experimentConstants from '../constants/experimentConstants';
import catalogModule from '../catalogModule';

function experimentationService() {
  return {
    getABTestEnrollment(projectId, layerName, parameters) {
      const ixpPromise = ExperimentationService.getAllValuesForLayer(layerName);
      ixpPromise.then(() => {
        ExperimentationService.logLayerExposure(layerName);
      });
      return ixpPromise;
    }
  };
}
catalogModule.factory('experimentationService', experimentationService);

export default experimentationService;
