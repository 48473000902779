import catalogModule from '../catalogModule';

function catalogLayoutService($log, languageResource) {
  'ngInject';

  const lang = languageResource;
  return {
    itemStatusLabels: {
      Sale: lang.get('Label.Sale'),
      New: lang.get('Label.New'),
      XboxExclusive: lang.get('Label.Xbox'),
      AmazonExclusive: lang.get('Label.Amazon'),
      GooglePlayExclusive: lang.get('Label.GoogleOnly'),
      IosExclusive: lang.get('Label.AppleOnly')
    }
  };
}

catalogModule.factory('catalogLayoutService', catalogLayoutService);

export default catalogLayoutService;
