import catalogModule from '../catalogModule';

function vngPaymentsService(httpService, catalogConstants) {
  'ngInject';

  return {
    getVngShopSignedUrl() {
      return httpService.httpGet(catalogConstants.endpoints.getSignedVngShopUrl);
    }
  };
}
catalogModule.factory('vngPaymentsService', vngPaymentsService);

export default vngPaymentsService;
