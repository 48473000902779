import { EnvironmentUrls, Endpoints, CurrentUser, DisplayNames } from 'Roblox';
import { concatTexts, seoName } from 'core-utilities';
import catalogModule from '../catalogModule';

function utilityService($filter, catalogConstants, menuConstants, $location, catalogLayoutService) {
  'ngInject';

  function buildItemDetailsUrl(item) {
    const { itemType, id, name } = item;
    const { itemTypes } = catalogConstants;

    switch (itemType) {
      case itemTypes.bundle:
        return Endpoints.getAbsoluteUrl
          ? Endpoints.getAbsoluteUrl(`/bundles/${id}/`)
          : `${EnvironmentUrls.websiteUrl}/bundles/${id}/`;
      case itemTypes.asset:
      default:
        return Endpoints.getAbsoluteUrl
          ? Endpoints.getAbsoluteUrl(`/catalog/${id}/`)
          : `${EnvironmentUrls.websiteUrl}/catalog/${id}/`;
    }
  }

  function getNameForDisplay(item) {
    const { userTypes, robloxSystemUserId } = catalogConstants;
    const { creatorName, creatorType, creatorTargetId } = item;
    return userTypes.user === creatorType &&
      robloxSystemUserId !== creatorTargetId &&
      DisplayNames.Enabled()
      ? concatTexts.concat(['', $filter('escapeHtml')(creatorName)])
      : $filter('escapeHtml')(creatorName);
  }

  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, escape);
  }

  return {
    isSplashPage() {
      const queries = $location.search();
      const { category, subcategory, keyword, catalogContext } = catalogConstants.queryNames;
      return (
        queries &&
        !queries[category] &&
        !queries[subcategory] &&
        !queries[keyword] &&
        !queries[catalogContext]
      );
    },

    getQueriesValueIntoInt() {
      const queries = $location.search();
      const { queryValueIsInt } = catalogConstants;
      for (const [key, value] of Object.entries(queries)) {
        if (queryValueIsInt.indexOf(key.toLowerCase()) > -1) {
          queries[key] = parseInt(value);
        } else {
          queries[key] = value;
        }
      }

      return queries;
    },

    // The plan is transitioning api response data to current stupid weird client side library data object. After we fully rollout, I will come back to fix the weird object
    // another purpose here is process data to match legacy data model to prevent page broken
    buildNavigationMenu(rawData, library) {
      const categoriesWithCreatorFilters = [];
      rawData.categoriesWithCreator &&
        rawData.categoriesWithCreator.forEach(category => {
          categoriesWithCreatorFilters.push(category);
        });
      const libraryData = {
        robloxUserId: rawData.robloxUserId,
        robloxUserName: rawData.robloxUserName,
        defaultCurrencyId: rawData.defaultCurrency,
        freeFilterId: rawData.freeFilter,
        customRobuxFilterId: rawData.customRobuxFilter,
        robuxFilterId: rawData.robuxFilter,
        allCategoriesId: rawData.allCategories,
        defaultCategoryId: rawData.defaultCategory,
        defaultCategoryIdForRecommendedSearch: rawData.defaultCategoryIdForRecommendedSearch,
        gearCategoryId: rawData.gearSubcategory,
        defaultGearSubcategoryId: menuConstants.defaultGearSubcategoryId,
        defaultSortTypeId: rawData.defaultSortType,
        defaultSortAggregationId: rawData.defaultSortAggregation,
        categoriesWithCreatorFilters,
        defaultCreatorId: rawData.defaultCreator
      };
      library.categoryEnumLibrary = {};
      const categoryEnums = {};
      rawData.categories.forEach(category => {
        category.typeId = category.categoryId;
        category.Data = category.typeId.toString(); // for transition
        category.Description = category.name;

        categoryEnums[category.categoryId] = {
          categoryEnum: category.category,
          subcategoryEnums: {}
        };
        if (category.subcategories) {
          const subcategoryEnums = {};
          category.subcategories.forEach(subCategory => {
            subCategory.typeId = subCategory.subcategoryId;
            subCategory.Data = subCategory.typeId.toString(); // for transition
            subCategory.Description = subCategory.name;
            subcategoryEnums[subCategory.subcategoryId] = subCategory.subcategory;
          });

          Object.assign(categoryEnums[category.categoryId].subcategoryEnums, subcategoryEnums);
        }
      });
      Object.assign(library.categoryEnumLibrary, categoryEnums);

      library.priceEnumLibrary = {};
      const priceEnumLibrary = {};
      if (rawData.priceFilters !== null) {
        rawData.priceFilters.forEach(price => {
          price.typeId = price.currencyType.toString(); // for transition
          price.Data = price.typeId.toString(); // for transition
          price.Description = price.name;
          priceEnumLibrary[price.currencyType] = {
            typeId: price.currencyType,
            name: price.name
          };
        });
      }

      Object.assign(library.priceEnumLibrary, priceEnumLibrary);

      rawData.creatorFilters.forEach(creator => {
        creator.Data = creator.userId.toString(); // for transition
        creator.Description = creator.name;
      });

      const { sortOptions, sortAggregations } = rawData.sortMenu;
      sortOptions.forEach(sortOption => {
        sortOption.typeId = sortOption.sortType;
        sortOption.Data = sortOption.typeId.toString();
        sortOption.Description = sortOption.name;
      });
      sortAggregations.forEach(sortAggregation => {
        sortAggregation.typeId = sortAggregation.sortAggregation;
        sortAggregation.Data = sortAggregation.typeId.toString();
        sortAggregation.Description = sortAggregation.name;
      });

      library.salesTypeFilters = [];
      const salesTypeFilters = [];
      rawData.salesTypeFilters.forEach(salesType => {
        const salesTypeFilter = {};
        salesTypeFilter.name = salesType.name.toString(); // for transition
        salesTypeFilter.filter = salesType.filter;
        salesTypeFilters.push(salesTypeFilter);
      });
      Object.assign(library.salesTypeFilters, salesTypeFilters);

      Object.assign(library, libraryData);
    },

    getCatalogContentKey(item) {
      const { id, itemType } = item;
      return `${itemType}_${id}`;
    },

    updateSearchItemDetails(updatedDetails, searchResultDict) {
      if (updatedDetails) {
        updatedDetails.forEach(item => {
          const currentItem = item;
          const { key } = item;
          currentItem.detailsUrl = buildItemDetailsUrl(item);
          currentItem.nameForDisplay = getNameForDisplay(item);
          currentItem.detailsLoaded = true;
          Object.assign(searchResultDict[key], currentItem);
        });
      }
    },

    updateSearchItemThumbnails(thumbnails, searchResultDict) {
      if (thumbnails) {
        thumbnails.forEach(thumbnail => {
          const { key } = thumbnail;
          const thumbnailObject = {
            thumbnail
          };
          Object.assign(searchResultDict[key], thumbnailObject);
        });
      }
    },

    formatQueries(queries) {
      const formatQueries = {};
      const { mappingToApiParameters } = menuConstants;
      for (const key in queries) {
        const queryValue = queries[key];
        const apiKey = mappingToApiParameters[key.toLowerCase()];
        if (apiKey) {
          formatQueries[apiKey] = queryValue;
        }
      }
      return formatQueries;
    },

    mapItemRestrictionIcons(item) {
      if (item && item.itemRestrictions) {
        const { itemTypes, itemRestrictionTypes, itemRestrictionIcons } = catalogConstants;
        if (item.itemType === itemTypes.bundle) {
          item.isLimited = item.itemRestrictions.indexOf(itemRestrictionTypes.limited) > -1;
          item.isCollectible = item.itemRestrictions.indexOf(itemRestrictionTypes.collectible) > -1;
          item.isRthro = item.itemRestrictions.indexOf(itemRestrictionTypes.rthro) > -1;
          item.isDynamicHead = item.itemRestrictions.indexOf(itemRestrictionTypes.dynamicHead) > -1;
          if (item.isLimited) {
            item.itemRestrictionIcon = item.isRthro
              ? itemRestrictionIcons.rthroLimitedLabel
              : itemRestrictionIcons.rthroLabel;
          } else if (item.isDynamicHead) {
            item.itemRestrictionIcon = itemRestrictionIcons.dynamicHead;
          } else if (item.isCollectible) {
            item.itemRestrictionIcon = itemRestrictionIcons.collectible;
          }
        } else {
          item.isThirteenPlus =
            item.itemRestrictions.indexOf(itemRestrictionTypes.thirteenPlus) > -1;
          item.isLimitedUnique =
            item.itemRestrictions.indexOf(itemRestrictionTypes.limitedUnique) > -1;
          item.isLimited = item.itemRestrictions.indexOf(itemRestrictionTypes.limited) > -1;
          item.isCollectible = item.itemRestrictions.indexOf(itemRestrictionTypes.collectible) > -1;
          item.isDynamicHead = item.itemRestrictions.indexOf(itemRestrictionTypes.dynamicHead) > -1;
          if (item.isLimitedUnique) {
            item.itemRestrictionIcon = item.isThirteenPlus
              ? itemRestrictionIcons.thirteenPlusLimitedUnique
              : itemRestrictionIcons.limitedUnique;
          } else if (item.isLimited) {
            item.itemRestrictionIcon = item.isThirteenPlus
              ? itemRestrictionIcons.thirteenPlusLimited
              : itemRestrictionIcons.limited;
          } else if (item.isThirteenPlus) {
            item.itemRestrictionIcon = itemRestrictionIcons.thirteenPlus;
          } else if (item.isDynamicHead) {
            item.itemRestrictionIcon = itemRestrictionIcons.dynamicHead;
          } else if (item.isCollectible) {
            item.itemRestrictionIcon = itemRestrictionIcons.collectible;
          }
        }
      }
    },

    mapItemStatusIconsAndLabels(item) {
      if (item && item.itemStatus) {
        const { itemStatusClasses, itemStatusHasIcons, itemStatusIcons } = catalogConstants;
        const { itemStatusLabels } = catalogLayoutService;
        item.itemStatusIconsAndLabels = [];
        item.itemStatus.forEach(status => {
          if (itemStatusHasIcons.indexOf(status) > -1) {
            item.itemStatusIconsAndLabels.push({
              isIcon: true,
              type: itemStatusIcons[status]
            });
          } else {
            item.itemStatusIconsAndLabels.push({
              class: itemStatusClasses[status],
              label: itemStatusLabels[status]
            });
          }
        });
      }
    },

    translateToEnumStrings(library, queries) {
      const { categoryEnumLibrary } = library;
      const { category, subcategory, gears } = queries;
      if (categoryEnumLibrary && categoryEnumLibrary[category]) {
        const { categoryEnum, subcategoryEnums } = categoryEnumLibrary[category];
        const translatedEnums = {
          category: categoryEnum,
          subcategory: gears ? subcategoryEnums[gears] : subcategoryEnums[subcategory]
        };
        Object.assign(queries, translatedEnums);
      }
    },

    buildUserLink(item) {
      const { creatorType, creatorTargetId } = item || {};
      const { userTypes } = catalogConstants;
      switch (creatorType) {
        case userTypes.group:
          item.creatorLink = Endpoints.getAbsoluteUrl(`/groups/${creatorTargetId}`);
          break;
        case userTypes.user:
        default:
          item.creatorLink = Endpoints.getAbsoluteUrl(`/users/${creatorTargetId}/profile`);
          break;
      }
    },

    buildUserShopLink(item) {
      const { creatorType, creatorName } = item || {};
      const { userTypes } = catalogConstants;
      switch (creatorType) {
        case userTypes.group:
          item.creatorLink = Endpoints.getAbsoluteUrl(
            `/catalog/?Category=1&CreatorName=${fixedEncodeURIComponent(
              creatorName
            )}&CreatorType=Group`
          );
          break;
        case userTypes.user:
        default:
          item.creatorLink = Endpoints.getAbsoluteUrl(
            `/catalog/?Category=1&CreatorName=${fixedEncodeURIComponent(creatorName)}`
          );
          break;
      }
    },

    buildErrorMessages(errors, userAgent) {
      let errorMessage = `userId-${CurrentUser.userId}`;
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          const { code, message } = error;
          errorMessage += `-code-${code}-message-${message}`;
        });
      }
      errorMessage += `-userAgent-${userAgent}`;
      return errorMessage;
    }
  };
}

catalogModule.factory('utilityService', utilityService);

export default utilityService;
