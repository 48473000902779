import React, { useRef } from 'react';
import { Topic } from '../../../constants/types';
import { CatalogQuery } from '../../../hooks/catalogQuery/catalogQuery.types';
import UtilityService from '../../../services/utilityService';
import useTopics from './useTopics';

export type TopicCarouselProps = {
  topicBasedBrowsingEnabledForCategory?: boolean;
  catalogQuery: CatalogQuery;
  topics: Topic[];
  setSelectedTopics: (selectedTopics: Topic[]) => void;
  onClearFilters: (keepKeyword: boolean) => void;
  clearTopics: () => void;
};

export function TopicsCarousel(props: TopicCarouselProps): JSX.Element {
  const {
    topicBasedBrowsingEnabledForCategory,
    catalogQuery,
    topics,
    setSelectedTopics,
    onClearFilters,
    clearTopics
  } = props;

  const carouselRef = useRef<HTMLDivElement>(null);

  const {
    showLeftTopicNavigationButton,
    onTopicNavigationButtonClicked,
    showClearTopicNavigationButton,
    onTopicClearButtonClicked,
    onTopicClicked,
    showRightTopicNavigationButton
  } = useTopics(catalogQuery, topics, setSelectedTopics, onClearFilters, clearTopics, carouselRef);

  return (
    <React.Fragment>
      {topicBasedBrowsingEnabledForCategory && (
        <div className='topic-container'>
          {/* Topic Navigation Left */}
          {showLeftTopicNavigationButton && (
            <span className='topic-navigation-left topic-navigation-blur-left'>
              <button
                type='button'
                className='topic-navigation-button'
                onClick={() => onTopicNavigationButtonClicked(true)}>
                <span className='icon-left-gray' />
              </button>
            </span>
          )}

          {/* Topic Clear Button */}
          {showClearTopicNavigationButton && (
            <span className='topic-navigation-clear'>
              <button
                type='button'
                className='topic-navigation-button'
                onClick={onTopicClearButtonClicked}>
                <span className='icon-actions-clear-sm' />
              </button>
            </span>
          )}

          {/* Topics */}
          <span className='topic-carousel' id='topic-carousel' ref={carouselRef}>
            {catalogQuery.topics.map(topic => (
              <button
                type='button'
                key={`${topic.displayName}-${topic.originalTopicName}`}
                className='topic selected-topic selected-topic-react'
                onClick={() => onTopicClicked(topic)}>
                {UtilityService.formatTopic(topic.displayName)}
                <span className='icon-close' />
              </button>
            ))}
            {topics.map(topic => (
              <button
                type='button'
                key={`${topic.displayName}-${topic.originalTopicName}`}
                className='topic unselected-topic'
                onClick={() => onTopicClicked(topic)}>
                {UtilityService.formatTopic(topic.displayName)}
              </button>
            ))}
          </span>

          {/* Placeholder */}
          {(!catalogQuery.topics ||
            !topics ||
            (catalogQuery.topics.length === 0 && topics.length === 0)) && (
            <span className='topic-carousel-placeholder' />
          )}

          {/* Topic Navigation Right */}
          {showRightTopicNavigationButton && (
            <span className='topic-navigation-right topic-navigation-blur-right'>
              <button
                type='button'
                className='topic-navigation-button'
                onClick={() => onTopicNavigationButtonClicked(false)}>
                <span className='icon-right-gray' />
              </button>
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default TopicsCarousel;
