import catalogModule from '../catalogModule';

function range() {
  'ngInject';

  return function(input, total) {
    total = total || 30;
    total = parseInt(total, 10);

    for (let i = 0; i < total; i++) {
      input.push(i);
    }

    return input;
  };
}

catalogModule.filter('range', range);

export default range;
