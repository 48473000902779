import angular from 'angular';
import { importFilesUnderPath, templateCacheGenerator } from 'roblox-es6-migration-helper';

import '../../../css/catalog/catalog.scss';

// import main module definition.
import catalogModule from './catalogModule';

importFilesUnderPath(require.context('./filters/', true, /\.js$/));
importFilesUnderPath(require.context('./constants/', true, /\.js$/));
importFilesUnderPath(require.context('./directives/', true, /\.js$/));
importFilesUnderPath(require.context('./filters/', true, /\.js$/));
importFilesUnderPath(require.context('./services/', true, /\.js$/));
importFilesUnderPath(require.context('./controllers/', true, /\.js$/));

const templateContext = require.context('../catalog/', true, /\.html$/);
const templateDesktopContext = require.context('./', true, /\.html$/);

const templates = templateCacheGenerator(
  angular,
  'catalogPageTemplates',
  templateContext,
  templateDesktopContext
);

// self manual initialization
angular.element(function() {
  angular.bootstrap('#catalog-container', [catalogModule.name, templates.name]);
});
