import catalogModule from '../catalogModule';

function metricsService(
  $log,
  httpService,
  catalogConstants,
  googleAnalyticsEventsService,
  utilityService
) {
  'ngInject';

  function sendErrorCount(data) {
    const { endpoints } = catalogConstants;
    const url = endpoints.performanceMeasurements;
    return httpService.httpPost(url, data);
  }

  return {
    sendErrorsToLogCount(result, endpointName) {
      const { errors } = result;
      const { categoryName } = catalogConstants.errorMessages;
      const postData = [];
      errors.forEach(error => {
        const { code } = error;
        const measureName = endpointName + code;
        postData.push({
          featureName: categoryName,
          measureName,
          value: 1 // log each time
        });
      });
      if (postData.length > 0) {
        sendErrorCount(postData);
      }
    },

    sendErrorsToGoogleAnalytics(result, endpointName) {
      const { errors } = result;
      const { categoryName } = catalogConstants.errorMessages;
      const errorMessages = utilityService.buildErrorMessages(
        errors,
        googleAnalyticsEventsService.getUserAgent()
      );
      googleAnalyticsEventsService.fireEvent(categoryName, endpointName, errorMessages);
    }
  };
}

catalogModule.factory('metricsService', metricsService);

export default metricsService;
