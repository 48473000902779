import catalogModule from "../catalogModule";

function searchBar(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.searchBarTemplate
  };
}

catalogModule.directive('searchBar', searchBar);

export default searchBar;
