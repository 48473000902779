import {
  CurrentUser,
  DeviceMeta,
  Dialog,
  SharedElementManipulator,
  BootstrapWidgets,
  TranslationResourceProvider,
  EnvironmentUrls
} from 'Roblox';
import {
  paymentFlowAnalyticsService,
  localStorageService,
  eventStreamService
} from 'core-roblox-utilities';
import angular from 'angular';
import { initRobloxBadgesFrameworkAgnostic } from 'roblox-badges';
import { reportWebVitals } from '@rbx/web-vitals';
import catalogModule from '../catalogModule';
import menuConstants from '../constants/menuConstants';
import {
  sendCatalogSearchEvent,
  sendSearchAutocompleteEvent,
  sendSearchClearEvent,
  sendSearchSuggestionClickedEvent,
  sendSearchTextTrimEvent,
  sendTopicSelectedEvent,
  sendTopicDeselectedEvent,
  sendTopicClearedEvent
} from '../utils/events';
import experimentConstants from '../constants/experimentConstants';
import { tryMountCartButton } from '../../../react/shoppingCart/utils/cartBtnMounter';
import renderCatalogReactComponent, {
  unmountCatalogReactComponent
} from '../../../react/catalog/utils/catalogPageMounter';

function catalogController(
  $timeout,
  $location,
  $http,
  $log,
  $scope,
  $anchorScroll,
  abpService,
  catalogService,
  catalogConstants,
  languageResource,
  utilityService,
  metricsService,
  thumbnailConstants,
  experimentationService,
  modalService,
  universalAppConfigurationService,
  vngPaymentsService
) {
  'ngInject';

  $scope.splashTiles = [];
  $scope.showSplashTiles = true;
  $scope.currentUserId = CurrentUser ? CurrentUser.userId : null;

  $scope.landingPageInfiniteScrollEnabled = true;

  $scope.autocompleteAvatarSearchNumToDisplay = 0;
  $scope.isCatalogAdsRowOnRecommendedPageEnabled = false;
  $scope.useAutocompleteFallback = false;

  $scope.directPurchaseEnabled = false;
  $scope.topicBasedBrowsingEnabled = true;
  $scope.topicBasedBrowsingDisplayEnabled = true;
  $scope.topicBasedBrowsingEnabledForCategory = false;
  $scope.triggeredByTopicDiscovery = false;
  $scope.topics = [];
  $scope.selectedTopics = [];
  $scope.topicCarouselWidth = 0;

  $scope.redirectBuyRobuxToVngShop = false;
  $scope.vngShopUrl = catalogConstants.fallbackVngShopUrl;

  $scope.$on('$locationChangeSuccess', () => {
    if (!$scope.layout.initialized) {
      return;
    }

    if ($scope.layout.preventLocationChange) {
      $scope.layout.preventLocationChange = false;
      return;
    }

    if ($scope.navigationMenu.filtersInitialized) {
      $scope.setSelectedFilters();
    }

    if ($scope.queries && !$scope.queries.category) {
      const { category, subcategory } = $scope.currentQuery;
      $scope.queries.category = category && category.categoryId;
      $scope.queries.subcategory = subcategory && subcategory.subcategoryId;
    }

    $scope.expandSelectedCategoryMenu();

    if (!$scope.library.isSplashPage) {
      $scope.getPagedItems(true);
      $scope.adRefresh();
    }
  });

  $scope.setupAds = () => {
    if (!$scope.layout.adsInitialized) {
      $scope.layout.adsInitialized = true;
      abpService.registerAd(abpService.adIds.leaderboardAbp);
    }
  };

  $scope.adRefresh = () => {
    abpService.refreshAllAds();
  };

  $scope.setupLibrary = () => {
    $scope.library = {};
    const libraryData = {
      isPhone: DeviceMeta ? DeviceMeta().isPhone : false,
      isDesktop: DeviceMeta ? DeviceMeta().isDesktop : true,
      isApp: DeviceMeta ? DeviceMeta().isInApp : false,
      buyRobuxUrl: catalogConstants.buyRobuxUrl,
      catalogUrl: catalogConstants.catalogUrl,
      isSplashPage: utilityService.isSplashPage()
    };
    $scope.library = { ...libraryData };
  };

  $scope.checkIfFullScreen = () => {
    const isFullScreen = angular.element('#container-main').hasClass('full-screen');
    $scope.library.isFullScreen = isFullScreen;
  };

  $scope.initialize = () => {
    if ($scope.layout && $scope.layout.initialized) {
      return;
    }
    $scope.forms = {};
    $scope.data = {}; // sunsetting, please do not add more data into this object
    $scope.navigationMenu = {};
    $scope.searchResultDict = {}; // store catalog items, bundle items...
    $scope.searchResultList = []; // keep the order
    $scope.paginations = { ...catalogConstants.initializedPaginations };
    $scope.searchLayout = {
      keyword: null,
      keywordForDisplay: null
    };
    $scope.queries = {}; // params for search
    $scope.currentQuery = {}; // store current category and subcategory
    $scope.autocompleteSuggestions = [];
    $scope.layout = angular.copy(catalogConstants.layout);

    $scope.autocompleteSuggestions = {
      show: false,
      timeSinceLastSearchAutocompleteEvent: 0,
      suggestions: [],
      textSuggestions: [],
      previousQuery: '',
      previousQueryBeforeRemoval: '',
      previousCharInput: -1,
      backspaceInput: false,
      startTime: 0,
      keyboardPosition: -1,
      locale: ''
    };

    $scope.topUp = {
      featureEnabled: false,
      showTopUp: false,
      currentUserBalance: -1
    };

    $scope.userLocale = {};

    reportWebVitals({
      logWebVitalsEvent: metric => {
        eventStreamService.sendEventWithTarget(metric.eventName, 'RobloxWWW', {
          metricName: metric.metricName,
          metricValue: metric.metricValue
        });
      }
    });

    $scope.setupLibrary();
    $scope.checkIfFullScreen();
    $scope.getMetadataFromApi();
    $scope.setupAds();
    $scope.getUserLocale();
    $scope.getUserCurrency();

    $scope.useReactCatalog = false;

    $scope.avatarShopPlacement = undefined;
    $scope.isItemDetailsEnabled = false;
    $scope.getAvatarShopPageEnrollment();
    $scope.getAvatarShopRecommendationsAndSearchWebEnrollment();
    $scope.getavatarShopRecommendationsAndSearchEnrollment();
    $scope.getAvatarMarketplaceGuidedFeaturesEnrollment();
    $scope.getAvatarMarketplaceShoppingCartEnrollment();
    $scope.getAvatarSortsEnrollment();

    $scope.getVngBuyRobuxPolicy();
    $scope.getVngShopUrl();
  };

  $scope.getAvatarShopPageEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarShopPage,
        experimentConstants.parameterNames.avatarShopPage
      )
      .then(
        function success(result) {},
        function error() {}
      );
  };

  $scope.getAvatarShopRecommendationsAndSearchWebEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarShopRecommendationsAndSearchWeb,
        experimentConstants.parameterNames.avatarShopRecommendationsAndSearchWeb
      )
      .then(
        function success(result) {
          if (
            result?.directPurchaseEnabled !== undefined &&
            result?.directPurchaseEnabled !== null
          ) {
            $scope.directPurchaseEnabled = result.directPurchaseEnabled;
          }
        },
        function error() {}
      );
  };

  $scope.getavatarShopRecommendationsAndSearchEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarShopRecommendationsAndSearch,
        experimentConstants.parameterNames.avatarShopRecommendationsAndSearch
      )
      .then(
        function success(result) {
          if (result?.infiniteScroll !== undefined && result?.infiniteScroll !== null) {
            $scope.landingPageInfiniteScrollEnabled = result.infiniteScroll;
          }
        },
        function error() {}
      );
  };

  $scope.getAvatarMarketplaceGuidedFeaturesEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarMarketplaceGuidedFeatures,
        experimentConstants.parameterNames.avatarMarketplaceGuidedFeatures
      )
      .then(
        function success(result) {
          if (result?.topUpEnabled !== undefined && result?.topUpEnabled !== null) {
            $scope.topUp.featureEnabled = result?.topUpEnabled;
            if (
              $scope.topUp.featureEnabled &&
              result?.topUpVariant !== undefined &&
              result?.topUpVariant !== null
            ) {
              if (result?.topUpVariant === 'zeroBalance') {
                $scope.topUp = {
                  ...$scope.topUp,
                  featureEnabled: true,
                  robuxMinThreshold: -1,
                  robuxMaxThreshold: 0,
                  displayCategories: catalogConstants.topUp.displayCategories,
                  headerText: 'Heading.NeedMoreRobux',
                  messageText: 'Message.WithRobux',
                  buttonText: 'Action.BuyRobux',
                  buttonUrl: `${EnvironmentUrls.websiteUrl}/upgrades/robux?ctx=catalogNew`
                };
              } else if (result?.topUpVariant === 'lowBalance') {
                $scope.topUp = {
                  ...$scope.topUp,
                  featureEnabled: true,
                  robuxMinThreshold: 1,
                  robuxMaxThreshold: catalogConstants.topUp.robuxThreshold,
                  displayCategories: catalogConstants.topUp.displayCategories,
                  headerText: 'Heading.NeedMoreRobux',
                  messageText: 'Message.RunningLowRobux',
                  buttonText: 'Action.BuyRobux',
                  buttonUrl: `${EnvironmentUrls.websiteUrl}/upgrades/robux?ctx=catalogNew`
                };
              } else if (result?.topUpVariant === 'giftCard') {
                $scope.topUp = {
                  ...$scope.topUp,
                  featureEnabled: true,
                  robuxMinThreshold: -1,
                  robuxMaxThreshold: 0,
                  displayCategories: catalogConstants.topUp.displayCategories,
                  headerText: 'Heading.TreatYourself',
                  messageText: 'Message.GiftCardPurchase',
                  buttonText: 'Action.ShopGiftCards',
                  buttonUrl: 'https://roblox.cashstar.com/gift-card/buy/?ref=1023gcwidget',
                  giftCard: true
                };
              }

              $scope.checkShowTopUpWidget();
            }
          }
        },
        function error() {}
      );
  };

  $scope.getAvatarMarketplaceShoppingCartEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarShopRecommendationsAndSearchWeb,
        experimentConstants.parameterNames.avatarMarketplaceShoppingCart
      )
      .then(
        function success(result) {},
        function error() {}
      );
  };

  $scope.getAvatarSortsEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarMarketplaceSorts,
        experimentConstants.parameterNames.avatarMarketplaceSorts
      )
      .then(
        function success(result) {},
        function error() {}
      );
  };

  $scope.isInfiniteScrollWebEnabled = () => {
    const { isPhone } = $scope.library;
    return !isPhone;
  };

  $scope.getMetadataFromApi = () => {
    catalogService.getMetadataFromApi().then(
      function success(result) {
        const {
          isPremiumPriceOnItemTilesEnabled,
          isPremiumIconOnItemTilesEnabled,
          autocompleteAvatarSearchNumToDisplay,
          isCatalogAdsRowOnRecommendedPageEnabled
        } = result;
        const libraryData = {
          initialized: true,
          isPremiumPriceOnItemTilesEnabled,
          isPremiumIconOnItemTilesEnabled
        };
        Object.assign($scope.library, libraryData);

        $scope.getSearchOptions();
        $scope.autocompleteAvatarSearchNumToDisplay = autocompleteAvatarSearchNumToDisplay;
        $scope.isCatalogAdsRowOnRecommendedPageEnabled = isCatalogAdsRowOnRecommendedPageEnabled;

        $scope.useReactCatalog = $scope.checkUseReactCatalog();

        if ($scope.useReactCatalog) {
          $scope.setupReactCatalog();
        }
      },
      function error() {
        $scope.library.initialized = true;
        $scope.getSearchOptions();
      }
    );
  };

  $scope.getUserCurrency = () => {
    if (CurrentUser.isAuthenticated === true) {
      catalogService.getUserCurrency().then(
        function success(result) {
          $scope.topUp.currentUserBalance = result.robux;
        },
        function error() {}
      );
    }
  };

  $scope.onTopicBasedBrowsingInitialized = () => {
    document.addEventListener(
      'scroll',
      e => {
        const topicCarousel = document.getElementById(catalogConstants.topics.topicCarouselId);
        if (e.target === topicCarousel) {
          $scope.topicCarouselPosition = topicCarousel.scrollLeft;
          $scope.topicCarouselWidth = topicCarousel.scrollWidth - topicCarousel.clientWidth;

          $scope.updateTopicNavigationButtons();
        }
      },
      true
    );
  };

  $scope.postGetTopics = (items, topics) => {
    const filteredItems = [];
    const filteredTopics = [];
    let count = 0;
    if (topics.length > 0) {
      topics.forEach(topic => {
        filteredTopics.push($scope.formatTopic(topic.displayName));
      });
    } else if (items.length > 0) {
      items.forEach(item => {
        if (count < catalogConstants.topics.numberOfItemsToSend) {
          filteredItems.push({
            targetId: item.id,
            // asset or bundle
            itemType:
              item.itemType === 'Asset'
                ? catalogConstants.itemTypeIds.asset
                : catalogConstants.itemTypeIds.bundle
          });
        }
        count += 1;
      });
    }
    $scope.topics = [];
    catalogService.postGetTopics(filteredItems, filteredTopics, $scope.searchLayout.keyword).then(
      function success(result) {
        $scope.topics = result.topics;
        $scope.topicCarouselPosition = 0;
      },
      function error() {
        $scope.clearTopics();
      }
    );
  };

  $scope.onTopicClicked = topic => {
    if ($scope.selectedTopics.length === 0) {
      const currentId = $scope.currentQuery?.category?.categoryId;
      $scope.topicOrigin = currentId;
    }

    const index = $scope.selectedTopics.indexOf(topic);
    if (index > -1) {
      // Deselect
      sendTopicDeselectedEvent(
        topic.displayName,
        $scope.selectedTopics.indexOf(topic),
        $scope.currentQuery.category.name,
        $scope.currentQuery.subcategory?.name,
        $scope.searchLayout.keyword,
        $scope.buildTopicKeyword($scope.topics, ',')
      );

      $scope.selectedTopics.splice(index, 1);
    } else {
      // Select
      sendTopicSelectedEvent(
        topic.displayName,
        $scope.topics.indexOf(topic),
        $scope.currentQuery.category.name,
        $scope.currentQuery.subcategory?.name,
        $scope.searchLayout.keyword,
        $scope.buildTopicKeyword($scope.topics, ','),
        $scope.buildTopicKeyword($scope.selectedTopics, ',')
      );

      $scope.selectedTopics.push(topic);
    }

    $scope.searchLayout.topics = $scope.buildTopicKeyword($scope.selectedTopics, ' ');

    if ($scope.selectedTopics.length > 0) {
      $scope.searchKeyword(true);
      $scope.triggeredByTopicDiscovery = true;
      $scope.postGetTopics([], $scope.selectedTopics);
    } else {
      $scope.onTopicsCleared();
    }
    $scope.resetTopicView();
  };

  $scope.formatTopic = topic => {
    return topic.toLowerCase();
  };

  $scope.buildTopicKeyword = (selectedTopics, separator) => {
    let keyword = '';
    selectedTopics.forEach(topic => {
      if (keyword.length > 0) {
        keyword += separator;
      }
      keyword += $scope.formatTopic(topic.displayName);
    });

    return keyword;
  };

  $scope.parseTopicsFromString = (topics, separator) => {
    if (!topics || topics.length === 0) {
      return [];
    }
    const selectedTopics = topics.split(separator);
    const parsedSelectedTopics = [];

    selectedTopics.forEach(topic => {
      parsedSelectedTopics.push({
        displayName: topic,
        originalTopicName: ''
      });
    });
    return parsedSelectedTopics;
  };

  $scope.onClearFilters = keepKeyword => {
    $scope.clearKeywordAndCreator(false, keepKeyword);
  };

  $scope.clearKeywordAndCreator = (stopChaining, keepKeyword) => {
    if (stopChaining) {
      $scope.clearKeyword();
      return;
    }

    $scope.clearCreator(false, keepKeyword);
  };

  $scope.clearKeyword = () => {
    $scope.searchLayout.keyword = null;
    $scope.applySearch(true);
  };

  $scope.clearCreator = (stopChaining, keepKeyword) => {
    $scope.data.creator = $scope.data.creators.find(creator => {
      return creator.userId === $scope.library.defaultCreatorId;
    });

    if (stopChaining) {
      $scope.clearKeyword();
      return;
    }

    $scope.clearPrice(keepKeyword);
  };

  $scope.clearPrice = keepKeyword => {
    $scope.data.currencyType = $scope.data.currencyTypes ? $scope.data.currencyTypes[0] : null;

    if (keepKeyword) {
      $scope.applySearch(true);
    } else {
      $scope.clearKeyword();
    }
  };

  $scope.onTopicsCleared = () => {
    if ($scope.topicOrigin !== menuConstants.categoryTypes.Recommended) {
      $scope.onClearFilters(true);
      return;
    }
    $scope.currentQuery.category = $scope.getCategoryOptionFromCategoryId(
      menuConstants.categoryTypes.Recommended
    );

    $scope.onClearFilters(true);
  };

  $scope.showTopicNavigationButton = left => {
    const topicCarousel = document.getElementById(catalogConstants.topics.topicCarouselId);
    if (!topicCarousel) {
      return false;
    }
    $scope.topicCarouselWidth = topicCarousel.scrollWidth - topicCarousel.clientWidth;
    if (
      $scope.topics &&
      $scope.selectedTopics &&
      $scope.topics.length <= 0 &&
      $scope.selectedTopics.length <= 0
    ) {
      return false;
    }
    if (left) {
      return $scope.topicCarouselWidth > 0 && $scope.topicCarouselPosition > 0;
    }
    return (
      // Scroll can be off by less than 1 when using mouse scroll
      $scope.topicCarouselWidth > 0 && $scope.topicCarouselPosition + 1 < $scope.topicCarouselWidth
    );
  };

  $scope.showTopicNavigationClearButton = () => {
    return !$scope.showTopicNavigationButton(true) && $scope.selectedTopics.length > 1;
  };

  $scope.updateTopicNavigationButtons = () => {
    const topicCarousel = document.getElementById(catalogConstants.topics.topicCarouselId);
    if (!topicCarousel) {
      return;
    }
    $scope.topicCarouselWidth = topicCarousel.scrollWidth - topicCarousel.clientWidth;

    const leftResult = $scope.showTopicNavigationButton(true);
    $scope.showLeftTopicNavigationButton = leftResult;

    const navLeft = document.getElementById('topic-navigation-left');
    if (navLeft) {
      if ($scope.showLeftTopicNavigationButton) {
        navLeft.classList.remove('ng-hide');
      } else {
        navLeft.classList.add('ng-hide');
      }
    }

    const rightResult = $scope.showTopicNavigationButton(false);
    $scope.showRightTopicNavigationButton = rightResult;

    const navRight = document.getElementById('topic-navigation-right');
    if (navRight) {
      if ($scope.showRightTopicNavigationButton) {
        navRight.classList.remove('ng-hide');
      } else {
        navRight.classList.add('ng-hide');
      }
    }

    const clearResult = $scope.showTopicNavigationClearButton();
    $scope.showClearTopicNavigationButton = clearResult;

    const navClear = document.getElementById('topic-navigation-clear');
    if (navClear) {
      if ($scope.showClearTopicNavigationButton) {
        navClear.classList.remove('ng-hide');
      } else {
        navClear.classList.add('ng-hide');
      }
    }
  };

  $scope.resetTopicView = () => {
    const topicCarousel = document.getElementById(catalogConstants.topics.topicCarouselId);
    topicCarousel.scrollLeft = 0;

    $scope.updateTopicNavigationButtons();
  };

  window.onresize = () => {
    if (!$scope.topicBasedBrowsingDisplayEnabled) {
      return;
    }

    $scope.updateTopicNavigationButtons();
  };

  $scope.onTopicNavigationButtonClicked = left => {
    // topic-carousel
    const topicCarousel = document.getElementById(catalogConstants.topics.topicCarouselId);
    $scope.topicCarouselPosition = topicCarousel.scrollLeft;
    // Weird tracking because topicCarousel.scrollLeft does not update immediately
    if (left) {
      topicCarousel.scrollLeft -= catalogConstants.topics.topicCarouselScrollValue;
      $scope.topicCarouselPosition -= catalogConstants.topics.topicCarouselScrollValue;
    } else {
      topicCarousel.scrollLeft += catalogConstants.topics.topicCarouselScrollValue;
      $scope.topicCarouselPosition += catalogConstants.topics.topicCarouselScrollValue;
    }
  };

  $scope.clearTopics = () => {
    $scope.searchLayout.topics = null;
    $scope.topics = [];
    $scope.selectedTopics = [];
  };

  $scope.onTopicClearButtonClicked = () => {
    sendTopicClearedEvent(
      $scope.currentQuery.category.name,
      $scope.currentQuery.subcategory?.name,
      $scope.searchLayout.keyword,
      $scope.buildTopicKeyword($scope.topics, ',')
    );
    $scope.clearTopics();

    $scope.searchLayout.topics = $scope.buildTopicKeyword($scope.selectedTopics, ' ');

    $scope.onTopicsCleared();
  };

  $scope.getCatalogAdsABTestEnrollment = () => {
    experimentationService
      .getABTestEnrollment(
        experimentConstants.defaultProjectId,
        experimentConstants.layerNames.avatarShopPage,
        experimentConstants.parameterNames.catalogItemAds
      )
      .then(
        function success(result) {
          if (
            result?.avatarShopPlacement !== undefined &&
            result?.isItemDetailsEnabled !== undefined
          ) {
            $scope.avatarShopPlacement = result.avatarShopPlacement;
            $scope.isItemDetailsEnabled = result.isItemDetailsEnabled;
          }
        },
        function error() {}
      );
  };

  $scope.callBackForSearchOptions = () => {
    $scope.setSelectedFilters();
    $scope.setQuery(true);
    $scope.getPagedItems(true, true);
    // This is to ensure the external libraries are called to setup the menus after angular has finished rendering
    const waitForRenderAndSetupMenus = () => {
      if ($http.pendingRequests.length > 0) {
        $timeout(waitForRenderAndSetupMenus);
      } else {
        $scope.expandSelectedCategoryMenu();
        $scope.layout.resetVerticalMenu = true;
      }
    };

    $timeout(waitForRenderAndSetupMenus);
  };

  $scope.getSearchOptions = () => {
    $scope.layout.hasSearchOptionsError = false;

    catalogService
      .getNavigationMenuItems()
      .then(function success(result) {
        utilityService.buildNavigationMenu(result, $scope.library);

        $scope.setNavigationMenu(result);
        $scope.callBackForSearchOptions();
      })
      .finally(() => {
        $scope.layout.isNavigationMenuLoaded = true;
      });
  };

  $scope.getUserLocale = () => {
    const translationProvider = new TranslationResourceProvider();
    let { locale } = translationProvider.intl;
    $scope.autocompleteSuggestions.locale = locale;
    const regionChar = locale.indexOf('-');
    locale = locale.substring(0, regionChar !== -1 ? regionChar : locale.length);
    if (locale !== catalogConstants.englishLanguageCode) {
      locale += `,${catalogConstants.englishLanguageCode}`;
    }
    $scope.autocompleteLanguageCode = locale;
  };

  $scope.resetPaginations = () => {
    Object.assign($scope.paginations, catalogConstants.initializedPaginations);
  };

  $scope.resetPageContentAndLoading = clearResults => {
    $scope.layout.showNoResultsMessage = false;
    $scope.layout.showErrorMessage = false;
    $scope.layout.loading = false;

    $scope.layout.isItemDetailsLoaded = false;
    $scope.layout.isAssetThumbnailLoaded = false;
    $scope.layout.isBundleThumbnailLoaded = false;

    $scope.layout.isItemDetailsLoadingFailed = false;

    if (clearResults) {
      $scope.searchResultDict = {};
      $scope.searchResultList = [];

      if (!$scope.paginations.startPaging) {
        $scope.resetPaginations();
      }
    }
  };

  $scope.setPaginations = searchResult => {
    if ($scope.showViewAllFeaturedItemsButton()) {
      return false;
    }
    let isPaginationEnabled = $scope.isPaginationEnabled();

    const { previousPageCursor, nextPageCursor } = searchResult;
    Object.assign($scope.paginations, { previousPageCursor, nextPageCursor });
    const { currentPage } = $scope.paginations;
    if ($scope.paginations.direction === catalogConstants.pageDirection.next) {
      $scope.paginations.currentPage += 1;
    } else if ($scope.paginations.direction === catalogConstants.pageDirection.prev) {
      $scope.paginations.currentPage = currentPage > 0 ? currentPage - 1 : 0;
    } else {
      isPaginationEnabled = nextPageCursor || previousPageCursor;
    }

    $scope.paginations.isEnabled = isPaginationEnabled;
  };

  $scope.isPaginationEnabled = () => {
    const { isPhone, isSplashPage } = $scope.library;
    if (
      isPhone ||
      (!$scope.landingPageInfiniteScrollEnabled && $scope.isCurrentCategoryRecommended()) ||
      isSplashPage
    ) {
      return false; // pagination window is disabled when in phone or all featured page or recommended
    }
    return true;
  };

  $scope.buildSearchResultData = data => {
    if ($scope.searchResultList.length === 0 && (!data || data.length === 0)) {
      $scope.layout.showNoResultsMessage = true;
      return false;
    }

    const { itemTypes } = catalogConstants;
    const itemParamsMapKey = {};
    data.forEach(item => {
      const key = utilityService.getCatalogContentKey(item);
      item.key = key;
      $scope.searchResultDict[key] = item;
      if ($scope.searchResultList.indexOf(key) < 0) {
        $scope.searchResultList.push(key);
      }

      const itemId = item.id;
      if (item && item.itemType === itemTypes.bundle) {
        item.thumbnailType = thumbnailConstants.thumbnailTypes.bundleThumbnail;
      } else {
        item.thumbnailType = thumbnailConstants.thumbnailTypes.assetThumbnail;
      }
      itemParamsMapKey[itemId] = item;
    });

    catalogService
      .getCatalogItemDetails(itemParamsMapKey)
      .then(
        function success(details) {
          utilityService.updateSearchItemDetails(details, $scope.searchResultDict);
        },
        function error(result) {
          const { endpointNames } = catalogConstants.errorMessages;
          metricsService.sendErrorsToGoogleAnalytics(result, endpointNames.getCatalogItemDetails);
          metricsService.sendErrorsToLogCount(result, endpointNames.getCatalogItemDetails);
          $scope.layout.isItemDetailsLoadingFailed = true;
        }
      )
      .finally(() => {
        $scope.layout.isItemDetailsLoaded = true;

        // bootstraps the verified badges component
        try {
          initRobloxBadgesFrameworkAgnostic({
            overrideIconClass: 'verified-badge-icon-catalog-item'
          });
        } catch (e) {
          // noop
        }
      });
  };

  $scope.getSearchItems = (params, clearResults) => {
    utilityService.translateToEnumStrings($scope.library, params);
    catalogService
      .getSearchItems(
        params,
        $scope.library.isFullScreen,
        catalogConstants.expandedCategoryList.includes(params.category) ||
          (!catalogConstants.expandedCategoryList.includes(params.category) &&
            $scope.isInfiniteScrollWebEnabled())
      )
      .then(
        function success(searchResult) {
          $scope.resetPageContentAndLoading(clearResults);
          if (searchResult) {
            const { data, keyword } = searchResult;
            if ($scope.selectedTopics.length <= 0) {
              $scope.searchLayout.keyword = keyword;
              $scope.searchLayout.keywordForDisplay = keyword;
            }
            if (
              $scope.topicBasedBrowsingEnabled &&
              $scope.topicBasedBrowsingEnabledForCategory &&
              !$scope.searchResultList.length > 0
            ) {
              if ($scope.topicBasedBrowsingDisplayEnabled) {
                if (!$scope.selectedTopics.length > 0) {
                  if (data.length > 0) {
                    $scope.postGetTopics(data, []);
                  }
                } else {
                  $scope.postGetTopics([], $scope.selectedTopics);
                }
              }
            }

            $scope.setPaginations(searchResult);
            $scope.buildSearchResultData(data);
          }
        },
        result => {
          $log.debug(' ------ getCatalogResults error -------');
          $scope.resetPageContentAndLoading(clearResults);
          $scope.layout.showErrorMessage = true;

          const { endpointNames } = catalogConstants.errorMessages;
          metricsService.sendErrorsToGoogleAnalytics(result, endpointNames.getSearchItems);
          metricsService.sendErrorsToLogCount(result, endpointNames.getSearchItems);
        }
      )
      .finally(() => {
        if (!$scope.layout.initialized) {
          $scope.layout.initialized = true;
        }
        $scope.paginations.startPaging = false;
        $scope.layout.isSearchItemsLoaded = true;
        $scope.updateTopicNavigationButtons();
      });
  };

  $scope.getPagedItems = (clearResults, fadeResultsWhileLoading) => {
    if (clearResults && !fadeResultsWhileLoading) {
      $scope.searchResultDict = {};
      $scope.searchResultList = [];
    }
    $scope.layout.loading = true;
    $scope.getSearchItems($scope.queries, clearResults);
  };

  $scope.setNavigationMenu = data => {
    const { priceFilters, categories, sortMenu, creatorFilters, salesTypeFilters } = data;
    const navigationMenu = {
      categories,
      currencyTypes: priceFilters,
      sortMenus: sortMenu,
      salesTypeFilters
    };
    Object.assign($scope.navigationMenu, navigationMenu);

    $scope.setCreatorFilters(creatorFilters);
    $scope.navigationMenu.filtersInitialized = true;

    $scope.navigationMenu.categories.forEach(catagory => {
      // for now, only apply such sorting to subcategories of "Gear"
      if (catagory.categoryId === $scope.library.gearCategoryId) {
        catagory.subcategories.sort($scope.gearSubcategoryCompare);
      }
    });

    if (priceFilters && priceFilters.length > 0) {
      $scope.library.defaultCurrencyType = priceFilters[0];
    }

    // for transition to catalog api, pass back to old object
    Object.assign($scope.data, $scope.navigationMenu);
  };

  // compare subcategories of "Gear"
  $scope.gearSubcategoryCompare = (leftSubcategory, rightSubcategory) => {
    // check to pin "All Gear" to the top
    if (leftSubcategory.subcategoryId === catalogConstants.allGearSubcategoryId) {
      return -1;
    }

    if (rightSubcategory.subcategoryId === catalogConstants.allGearSubcategoryId) {
      return 1;
    }

    return languageResource.intl.langSensitiveCompare(leftSubcategory.name, rightSubcategory.name);
  };

  $scope.setSelectedFilters = () => {
    const query = utilityService.getQueriesValueIntoInt();
    $scope.data.query = query;
    $scope.queries = utilityService.formatQueries(query);
    $scope.topicBasedBrowsingEnabledForCategory = catalogConstants.topics.categoriesToShowTopics.includes(
      query.Category
    );
    $scope.triggeredByTopicDiscovery = query.TriggeredByTopicDiscovery;
    $scope.selectedTopics = $scope.parseTopicsFromString(query.topics, ',');
    $scope.searchLayout.topics = $scope.buildTopicKeyword($scope.selectedTopics, ' ');

    if (!$scope.topicBasedBrowsingDisplayEnabled && query.topics) {
      $scope.searchLayout.topics = null;
      $scope.triggeredByTopicDiscovery = false;
      $scope.searchKeyword(true);
    } else {
      $scope.searchLayout.keyword = query.Keyword;
      $scope.searchLayout.keywordForDisaply = query.Keyword;
    }

    $scope.setSelectedNavigationMenu(
      query.Category,
      query.Subcategory,
      query.Gears,
      query.CatalogContext
    );
    $scope.setCreatorValue(query.CreatorID, query.CreatorName, query.CreatorType);
    $scope.setCurrencyValue(query.CurrencyType, query.pxMin, query.pxMax);
    $scope.data.includeNotForSale = query.IncludeNotForSale || false;

    const initialSalesTypeFilterValue = query.salesTypeFilter
      ? parseInt(query.salesTypeFilter, 10)
      : 1;
    const salesTypeFilter = $scope.data.salesTypeFilters.find(salesFilter => {
      return salesFilter.filter === initialSalesTypeFilterValue;
    });

    $scope.data.salesTypeFilter = salesTypeFilter;

    $scope.setSortMenus(query.SortType, query.SortAggregation);
  };

  $scope.setSearchOptionsOpenValue = value => {
    $scope.layout.isSearchOptionsOpen = value;
    $scope.layout.hideMainView = value;
    SharedElementManipulator.toggleChat(value);
    SharedElementManipulator.toggleFooter(value);
    SharedElementManipulator.togglePagification(value);

    if (!value) {
      $anchorScroll(); // scroll to top of page
    }
  };

  $scope.toggleSearchOptions = open => {
    $scope.setSearchOptionsOpenValue(open);

    // if canceling changes to mobile search options, reset all filter selections
    if (!open) {
      $scope.setSelectedFilters();
      $scope.closeWarning();
    }
  };

  $scope.onKeywordKeypress = (event, fadeResultsWhileLoading) => {
    // if user hits Enter, perform search
    if (event.key === 'Enter') {
      if ($scope.autocompleteSuggestions.keyboardPosition === -1) {
        event.target.blur();

        // Clear topics
        $scope.clearTopics();
        $scope.triggeredByTopicDiscovery = false;

        $scope.searchKeyword(fadeResultsWhileLoading);

        $scope.autocompleteSuggestions.show = false;
        sendCatalogSearchEvent(0);
      } else {
        $timeout(function () {
          angular
            .element(
              document.querySelector(
                `#autocomplete-suggestion-${$scope.autocompleteSuggestions.keyboardPosition}`
              )
            )
            .triggerHandler('click');
        }, 0);
      }
    }
  };

  $scope.onKeywordKeydown = event => {
    if (event.which === 8) {
      $scope.autocompleteSuggestions.previousQueryBeforeRemoval = $scope.searchLayout.keyword;
      $scope.autocompleteSuggestions.backspaceInput = true;
    }

    if (event.which === 40 || event.which === 9) {
      const newPosition = $scope.autocompleteSuggestions.keyboardPosition + 1;
      const newSuggestion = angular.element(`#autocomplete-suggestion-li-${newPosition}`);
      if (newPosition < $scope.autocompleteSuggestions.suggestions.length) {
        event.stopPropagation();
        event.preventDefault();
        const previousSuggestion = angular.element(
          `#autocomplete-suggestion-li-${$scope.autocompleteSuggestions.keyboardPosition}`
        );
        if (previousSuggestion !== null) {
          previousSuggestion.removeClass('selected');
        }

        $scope.autocompleteSuggestions.keyboardPosition = newPosition;
        newSuggestion.addClass('selected');
      } else if (event.which !== 9) {
        event.stopPropagation();
        event.preventDefault();
      } else {
        angular
          .element(
            `#autocomplete-suggestion-li-${$scope.autocompleteSuggestions.suggestions.length - 1}`
          )
          .removeClass('selected');
        $scope.autocompleteSuggestions.show = false;
        $scope.autocompleteSuggestions.keyboardPosition = -1;
      }
    }

    if (event.which === 38) {
      event.stopPropagation();
      event.preventDefault();
      const previousSuggestion = angular.element(
        `#autocomplete-suggestion-li-${$scope.autocompleteSuggestions.keyboardPosition}`
      );
      if (previousSuggestion !== null) {
        previousSuggestion.removeClass('selected');
      }

      const newPosition = $scope.autocompleteSuggestions.keyboardPosition - 1;
      const newSuggestion = angular.element(`#autocomplete-suggestion-li-${newPosition}`);
      if (newPosition > -1) {
        $scope.autocompleteSuggestions.keyboardPosition = newPosition;
        newSuggestion.addClass('selected');
      } else {
        $scope.autocompleteSuggestions.keyboardPosition = -1;
      }
    }
    $scope.autocompleteSuggestions.previousCharInput = event.which;
  };

  $scope.keywordChanged = () => {
    if ($scope.searchLayout.keyword) {
      $scope.autocompleteSuggestions.show = true;
      $scope.getAutocompleteSuggestions($scope.searchLayout.keyword);
    } else {
      $scope.autocompleteSuggestions.suggestions = [];
      $scope.autocompleteSuggestions.textSuggestions = [];
    }
  };

  $scope.getAutocompleteSuggestions = searchTerm => {
    $scope.autocompleteSuggestions.startTime = Date.now();
    catalogService
      .getAvatarRequestSuggestion(
        searchTerm,
        $scope.autocompleteLanguageCode,
        catalogConstants.autocompleteQueryAmount,
        $scope.autocompleteSuggestions.previousQuery,
        $scope.useAutocompleteFallback
      )
      .then(
        function success(result) {
          // Remove outdated responses
          if (result.Args.Prefix === $scope.searchLayout.keyword) {
            $scope.autocompleteSuggestions.suggestions = [];
            $scope.autocompleteSuggestions.textSuggestions = [];
            let data = result.Data;
            data = data.slice(0, $scope.autocompleteAvatarSearchNumToDisplay);
            data.forEach(suggestion => {
              $scope.autocompleteSuggestions.suggestions.push(suggestion);
              $scope.autocompleteSuggestions.textSuggestions.push(suggestion.Query);
            });

            if (
              Date.now() >
                $scope.autocompleteSuggestions.timeSinceLastSearchAutocompleteEvent +
                  catalogConstants.autocompleteSuggestionEventData
                    .autocompleteSuggestionEventTimeoutDelay ||
              $scope.autocompleteSuggestions.timeSinceLastSearchAutocompleteEvent === null
            ) {
              $scope.autocompleteSuggestions.previousQuery = result.Args.Prefix;
              sendSearchAutocompleteEvent(
                result.Algo,
                $scope.autocompleteSuggestions.textSuggestions,
                result.Args.Prefix,
                $scope.autocompleteSuggestions.previousQuery,
                $scope.autocompleteSuggestions.locale,
                Date.now() - $scope.autocompleteSuggestions.startTime,
                $scope.autocompleteAvatarSearchNumToDisplay,
                $scope.autocompleteSuggestions.previousQuery !== ''
              );
            }
            if ($scope.autocompleteSuggestions.backspaceInput === true) {
              sendSearchTextTrimEvent(
                $scope.autocompleteSuggestions.previousQueryBeforeRemoval,
                result.Args.Prefix
              );
            }
            $scope.autocompleteSuggestions.timeSinceLastSearchAutocompleteEvent = Date.now();
            $scope.autocompleteSuggestions.backspaceInput = false;
          }
        },
        function error() {
          $scope.useAutocompleteFallback = true;
        }
      );
  };

  $scope.onKeywordInputClicked = event => {
    event.stopPropagation();
    $scope.autocompleteSuggestions.show = true;
  };

  $scope.onClickOutsideAutocomplete = () => {
    $scope.autocompleteSuggestions.show = false;
    $scope.autocompleteSuggestions.keyboardPosition = -1;
  };

  $scope.onAutocompleteSuggestionClicked = (suggestion, index) => {
    $scope.searchLayout.keyword = suggestion;
    $scope.searchKeyword(true);
    $scope.autocompleteSuggestions.keyboardPosition = -1;

    $scope.getAutocompleteSuggestions(suggestion);
    sendSearchSuggestionClickedEvent(
      $scope.searchLayout.keyword,
      index,
      suggestion,
      $scope.autocompleteSuggestions.textSuggestions
    );
    sendCatalogSearchEvent(1);

    $scope.autocompleteSuggestions.show = false;
    $scope.autocompleteSuggestions.keyboardPosition = -1;
  };

  $scope.onKeywordClear = event => {
    event.stopPropagation();
    sendSearchClearEvent($scope.searchLayout.keyword);
    document.getElementById('avatar-shop-keyword-input').focus();
    $scope.autocompleteSuggestions.show = true;
    $scope.searchLayout.keyword = '';
    $scope.autocompleteSuggestions.suggestions = [];
    $scope.autocompleteSuggestions.textSuggestions = [];
  };

  $scope.searchKeyword = fadeResultsWhileLoading => {
    $scope.applySearch(fadeResultsWhileLoading);
  };

  $scope.resetFiltersAndApplySearch = () => {
    $scope.onClearFilters(false);
  };

  $scope.applyMobileSearch = () => {
    if ($scope.forms.searchOptionsForm && !$scope.forms.searchOptionsForm.$valid) {
      $scope.layout.showError = true;
      return;
    }

    $scope.setSearchOptionsOpenValue(false);
    $scope.closeWarning();
    $scope.library.isSplashPage = false;
    if ($scope.isCurrentCategoryRecommended()) {
      $scope.resetFiltersAndApplySearch();
    } else {
      $scope.applySearch();
    }
  };

  $scope.closeWarning = () => {
    $scope.layout.showError = false;
  };

  $scope.getVngShopUrl = () => {
    vngPaymentsService.getVngShopSignedUrl().then(result => {
      $scope.vngShopUrl = result.vngShopRedirectUrl;
    });
  };

  $scope.getVngBuyRobuxPolicy = () => {
    universalAppConfigurationService.getVngBuyRobuxPolicy().then(
      result => {
        $scope.redirectBuyRobuxToVngShop = result.shouldShowVng;
      },
      () => {
        $scope.redirectBuyRobuxToVngShop = false;
      }
    );
  };

  $scope.buyRobuxButtonOnClick = () => {
    paymentFlowAnalyticsService.sendUserPurchaseFlowEvent(
      paymentFlowAnalyticsService.ENUM_TRIGGERING_CONTEXT.WEB_ROBUX_PURCHASE,
      false,
      paymentFlowAnalyticsService.ENUM_VIEW_NAME.CATALOG_LIST_PAGE,
      paymentFlowAnalyticsService.ENUM_PURCHASE_EVENT_TYPE.USER_INPUT,
      paymentFlowAnalyticsService.ENUM_VIEW_MESSAGE.BUY_ROBUX
    );
    if ($scope.redirectBuyRobuxToVngShop) {
      const bodyText = languageResource.get('Description.RedirectToPartnerWebsite', {
        linebreak: '\n\n'
      });
      const options = {
        titleText: languageResource.get('Heading.LeavingRoblox'),
        bodyText: bodyText,
        actionButtonShow: true,
        actionButtonText: languageResource.get('Action.ContinueToPayment'),
        actionButtonClass: 'btn-primary-md',
        neutralButtonText: languageResource.get('Action.Cancel'),
        closeButtonShow: true
      };
      modalService.open(options).result.then(() => {
        paymentFlowAnalyticsService.sendUserPurchaseFlowEvent(
          paymentFlowAnalyticsService.ENUM_TRIGGERING_CONTEXT.WEB_ROBUX_PURCHASE,
          false,
          paymentFlowAnalyticsService.ENUM_VIEW_NAME.CATALOG_LIST_PAGE,
          paymentFlowAnalyticsService.ENUM_PURCHASE_EVENT_TYPE.USER_INPUT,
          paymentFlowAnalyticsService.ENUM_VIEW_MESSAGE.CONTINUE_TO_VNG
        );
        window.open($scope.vngShopUrl, '_blank').focus();
      });
    } else {
      window.location = $scope.library.buyRobuxUrl;
    }
  };

  $scope.fetchNextFromInfiniteScroll = () => {
    if (
      $scope.layout.isSearchOptionsOpen ||
      $scope.layout.loading ||
      !$scope.paginations.nextPageCursor
    ) {
      return;
    }

    if (!$scope.layout.isItemDetailsLoaded && $scope.isInfiniteScrollWebEnabled()) {
      return;
    }
    if ($scope.library.isSplashPage) {
      $scope.library.isSplashPage = false;
      $scope.queries.category =
        $scope.currentQuery.category && $scope.currentQuery.category.categoryId;
      $scope.queries.subcategory =
        $scope.currentQuery.subcategory && $scope.currentQuery.subcategory.subcategoryId;
    }

    $scope.queries.cursor = $scope.paginations.nextPageCursor;
    if ($scope.isInfiniteScrollWebEnabled()) {
      $scope.getPagedItems(false, true);
    } else {
      $scope.getSearchItems($scope.queries);
    }
    $scope.paginations.direction = catalogConstants.pageDirection.next;
  };

  $scope.setQuery = () => {
    const params = {};

    $scope.setKeywordParam(params);
    $scope.setTopicParams(params);
    $scope.setCategoryParams(params);
    $scope.setCreatorParam(params);
    $scope.setPriceParams(params);
    $scope.setSalesTypeParams(params);

    if (
      $scope.data.sortType != null &&
      $scope.data.sortType.sortType !== $scope.library.defaultSortTypeId
    ) {
      params.SortType = $scope.data.sortType.sortType;
    }

    if (
      $scope.data.sortType != null &&
      $scope.data.sortType.hasSubMenu &&
      $scope.data.sortAggregation != null &&
      $scope.data.sortAggregation !== $scope.library.defaultSortAggregationId
    ) {
      params.SortAggregation = $scope.data.sortAggregation.sortAggregation;
    }

    if ($scope.data.includeNotForSale) {
      params.IncludeNotForSale = $scope.data.includeNotForSale;
    }

    $scope.data.query = params;
    $scope.queries = utilityService.formatQueries(params);
    $scope.topicBasedBrowsingEnabledForCategory = catalogConstants.topics.categoriesToShowTopics.includes(
      $scope.queries.category
    );
    $scope.layout.preventLocationChange = true;
    $location.search(params);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  $scope.setKeywordParam = params => {
    const { keyword, topics } = $scope.searchLayout;

    $scope.searchLayout.keywordForDisplay = null;
    if (keyword) {
      params.Keyword = keyword;
      $scope.searchLayout.keywordForDisplay = keyword;
    }
  };

  $scope.setCategoryParams = params => {
    // if on recommended, and there is a keyword, force users to search in another category
    // for this reason, setKeywordParam must run before this function
    if ($scope.isCurrentCategoryRecommended() && (params.Keyword || params.topics)) {
      const categoryIdForRecommendedSearch = $scope.library.defaultCategoryIdForRecommendedSearch;
      $scope.currentQuery.category = $scope.getCategoryMenu({
        categoryId: categoryIdForRecommendedSearch
      });
      $scope.currentQuery.subcategory = null;
      params.Category = categoryIdForRecommendedSearch;
      params.Subcategory = null;
      return;
    }

    if (
      $scope.currentQuery.category &&
      (!$scope.library.displaySplash || $scope.paginations.currentPage > 1)
    ) {
      params.Category = $scope.currentQuery.category.categoryId;

      if ($scope.currentQuery.subcategory != null) {
        if ($scope.currentQuery.category.categoryId === $scope.library.gearCategoryId) {
          // TODO: could remove this after rollout
          // if gear subcategory is the default, don't set it
          if (
            $scope.currentQuery.subcategory.subcategoryId !== catalogConstants.defaults.subcategory
          ) {
            params.Gears = $scope.currentQuery.subcategory.subcategoryId;
            params.Subcategory = $scope.currentQuery.subcategory.subcategoryId;
          }
        } else {
          params.Subcategory = $scope.currentQuery.subcategory.subcategoryId;
        }
      }
    }
  };

  $scope.setCreatorParam = params => {
    $scope.data.creatorBreadcrumb = null;

    // only add CreatorID if the selected ID is Roblox (1)
    if ($scope.data.creator && $scope.data.creator.userId !== $scope.library.defaultCreatorId) {
      if ($scope.data.creator.userId === $scope.library.robloxUserId) {
        params.CreatorID = $scope.data.creator.userId;
        $scope.data.creatorBreadcrumb = $scope.data.creator.name;
      } else if (
        $scope.data.creator.userId === $scope.layout.customText &&
        $scope.data.creatorName != null
      ) {
        params.CreatorName = $scope.data.creatorName;
        $scope.data.creatorBreadcrumb = $scope.data.creatorName;
      } else if ($scope.data.creator.userId) {
        params.CreatorID = $scope.data.creator.userId;
      }
    }

    if ($scope.data.creator && $scope.data.creator.type) {
      params.CreatorType = $scope.data.creator.type;
    }
  };

  $scope.setPriceParams = params => {
    const { currencyType } = $scope.data;
    $scope.data.showFreeBreadcrumb =
      currencyType && currencyType.currencyType === $scope.library.freeFilterId;
    $scope.data.minPriceBreadcrumb = null;
    $scope.data.maxPriceBreadcrumb = null;
    if (currencyType != null && currencyType.currencyType !== $scope.library.defaultCurrencyId) {
      params.CurrencyType = currencyType.currencyType;

      // if currencyType is Custom Robux, set min and max values
      if (currencyType.currencyType === $scope.library.customRobuxFilterId) {
        // only allow input that is less than or equal to 9 chars long
        $scope.data.minPrice =
          $scope.data.minPrice && $scope.data.minPrice.toString().length > 9
            ? null
            : $scope.data.minPrice;
        $scope.data.maxPrice =
          $scope.data.maxPrice && $scope.data.maxPrice.toString().length > 9
            ? null
            : $scope.data.maxPrice;
        const min = $scope.data.minPrice;
        const max = $scope.data.maxPrice;

        // if the supplied min is greater than the supplied max, reverse them
        if (max && min > max) {
          $scope.data.minPrice = max;
          $scope.data.maxPrice = min;
        }

        $scope.data.minPriceBreadcrumb = $scope.data.minPrice || 0;
        $scope.data.maxPriceBreadcrumb = $scope.data.maxPrice;

        if ($scope.data.minPrice === null && $scope.data.maxPrice === null) {
          params.pxMin = 0;
          params.pxMax = 0;
        } else {
          params.pxMin = $scope.data.minPrice;
          params.pxMax = $scope.data.maxPrice;
        }
      }

      if (currencyType.currencyType === $scope.library.freeFilterId) {
        params.pxMin = 0;
        params.pxMax = 0;
      }
    }
  };

  $scope.setTopicParams = params => {
    const { topics } = $scope.searchLayout;
    const selectedTopicArray = [];
    $scope.selectedTopics.forEach(topic => {
      selectedTopicArray.push(topic.displayName.toLowerCase());
    });
    if (topics) {
      params.TriggeredByTopicDiscovery = true;
      params.topics = $scope.buildTopicKeyword($scope.selectedTopics, ',');
    }
  };

  $scope.setSalesTypeParams = params => {
    params.salesTypeFilter = $scope.data.salesTypeFilter.filter;
  };

  $scope.setSelectedNavigationMenu = (category, subcategory, gears, catalogContext) => {
    if (!$scope.navigationMenu.categories) {
      return false;
    }

    if (!Number.isInteger(category, 10)) {
      // If a catalog context is provided, set the category to All instead of defaultCategoryId
      // defaultCategoryId can be Recommended or Featured depending on user ab test enrollment
      category = catalogContext ? $scope.library.allCategoriesId : $scope.library.defaultCategoryId;
    }

    let categoryOption = $scope.navigationMenu.categories.find(cat => {
      return cat.categoryId === category;
    });
    if (!categoryOption) {
      category = $scope.library.defaultCategoryId;
      categoryOption = $scope.navigationMenu.categories.find(cat => {
        return cat.categoryId === category;
      });
    }

    $scope.currentQuery.category = categoryOption;

    if (
      !categoryOption ||
      (categoryOption.subcategories && categoryOption.subcategories.length === 0)
    ) {
      $scope.currentQuery.subcategory = null;
      return false;
    }

    if (category === $scope.library.gearCategoryId) {
      let gear = gears ? gears[0] : null;
      if (!gear) {
        gear = categoryOption.subcategories[0].subcategoryId; // set to first item as default
      }

      // set subcategory to the gear attribute that is selected since we know the category is "Gear"
      $scope.currentQuery.subcategory = categoryOption.subcategories.find(subcat => {
        return subcat.subcategoryId === gear;
      });
    } else if (subcategory) {
      $scope.isLayeredClothingCategory = catalogConstants.layeredClothingSubcategories.includes(
        subcategory
      );
      // if no subcategory is defined then set it to the first item from the category submenu
      $scope.currentQuery.subcategory = categoryOption.subcategories.find(subcat => {
        return subcat.subcategoryId === subcategory;
      });
    }
    return false;
  };

  $scope.setCreatorFilters = creatorFilters => {
    if (!creatorFilters) {
      $scope.data.creators = null;
      return false;
    }

    creatorFilters.push({
      name: null,
      isSelected: false,
      userId: $scope.layout.customText
    });

    $scope.data.creators = creatorFilters;
    return true;
  };

  $scope.setCreatorValue = (creatorId, creatorName, creatorType) => {
    const creatorFilters = $scope.data.creators;

    if (creatorName) {
      $scope.data.creator = creatorFilters.find(creator => {
        return creator.userId === $scope.layout.customText;
      });
      $scope.data.creatorName = creatorName;
      $scope.data.creatorBreadcrumb = creatorName;
    } else {
      if (!creatorId) {
        creatorId = $scope.library.defaultCreatorId;
      }

      const creatorIdInt = parseInt(creatorId, 10);
      if (creatorIdInt > parseInt($scope.library.robloxUserId, 10)) {
        $scope.data.creator = creatorFilters.find(creator => {
          return creator.userId === $scope.layout.customText;
        });

        $scope.data.creator.userId = creatorIdInt;
      } else {
        $scope.data.creator = creatorFilters.find(creator => {
          return creator.userId === creatorId;
        });
      }

      $scope.data.creatorBreadcrumb =
        $scope.data.creator && $scope.data.creator.userId !== $scope.library.defaultCreatorId
          ? $scope.data.creator.name
          : null;
      $scope.data.creatorName = null;
    }

    if ($scope.data.creator && creatorType) {
      $scope.data.creator.type = creatorType;
    }
  };

  $scope.setCurrencyValue = (currencyType, minPrice, maxPrice) => {
    const { currencyTypes } = $scope.navigationMenu;
    if (!currencyTypes) {
      $scope.data.currencyType = null;
      return false;
    }

    if (currencyType == null) {
      $scope.data.currencyType = $scope.library.defaultCurrencyType;
    } else {
      $scope.data.currencyType = currencyTypes.find(currency => {
        return currency.currencyType === currencyType;
      });
    }

    $scope.data.showFreeBreadcrumb =
      $scope.data.currencyType &&
      $scope.data.currencyType.currencyType === $scope.library.freeFilterId;
    $scope.data.minPrice = null;
    $scope.data.maxPrice = null;

    if ($scope.data.currencyType.currencyType === $scope.library.customRobuxFilterId) {
      minPrice = minPrice ? parseInt(minPrice, 10) : null;
      maxPrice = maxPrice ? parseInt(maxPrice, 10) : null;
      $scope.data.minPrice = minPrice !== 0 ? minPrice : null;
      $scope.data.maxPrice = maxPrice !== 0 ? maxPrice : null;
      $scope.data.minPriceBreadcrumb = minPrice || 0;
      $scope.data.maxPriceBreadcrumb = maxPrice;
    }

    return false;
  };

  $scope.setSortMenus = (sortType, sortAggregation) => {
    const { sortMenus } = $scope.navigationMenu;
    if (!sortMenus) {
      $scope.data.sortType = null;
      $scope.data.sortAggregation = null;
      return false;
    }
    const { sortOptions, sortAggregations } = sortMenus;
    if (sortOptions) {
      $scope.data.defaultSortValue = sortOptions[0];
      if (sortType == null) {
        $scope.data.sortType = $scope.data.defaultSortValue;
      } else {
        $scope.data.sortType = sortOptions.find(sort => {
          return sort.sortType === sortType;
        });
      }
    } else {
      $scope.data.sortType = null;
    }
    if (sortAggregations) {
      $scope.data.defaultSortAggregationValue = sortAggregations[0];
      if (sortAggregation == null) {
        $scope.data.sortAggregation = $scope.data.defaultSortAggregationValue;
      } else {
        $scope.data.sortAggregation = sortAggregations.find(sort => {
          return sort.sortAggregation === sortAggregation;
        });
      }
    } else {
      $scope.data.sortAggregation = null;
    }
    return false;
  };

  $scope.setSortType = sortType => {
    const { sortMenus } = $scope.data;
    if (!sortMenus || !sortMenus[0]) {
      $scope.data.sortType = null;
      return false;
    }

    if (sortMenus[0].Sorts) {
      $scope.data.defaultSortValue = sortMenus[0].Sorts[0];
      if (sortType == null) {
        $scope.data.sortType = $scope.data.defaultSortValue;
      } else {
        $scope.data.sortType = sortMenus[0].Sorts.find(sort => {
          return sort.sortType === sortType;
        });
      }
    }
    return false;
  };

  $scope.setSortAggregation = sortAggregation => {
    const { sortMenus } = $scope.data;
    if (!sortMenus || !sortMenus[1]) {
      $scope.data.sortAggregation = null;
      return false;
    }

    if (sortMenus[1].Sorts) {
      $scope.data.defaultSortAggregationValue = sortMenus[1].Sorts[0];
      if (sortAggregation == null) {
        $scope.data.sortAggregation = $scope.data.defaultSortAggregationValue;
      } else {
        $scope.data.sortAggregation = sortMenus[1].Sorts.find(sort => {
          return sort.sortAggregation === sortAggregation;
        });
      }
    }

    return false;
  };

  $scope.getCategoryOptionFromCategoryId = categoryId => {
    if (categoryId < 0) {
      return null;
    }

    return $scope.navigationMenu.categories.find(c => {
      return c.categoryId === categoryId;
    });
  };

  $scope.getCategoryMenu = category => {
    if (!category) {
      return null;
    }

    return $scope.navigationMenu.categories.find(c => {
      return c.categoryId === category.categoryId;
    });
  };

  $scope.getCategoryOption = category => {
    return $scope.getCategoryMenu(category);
  };

  $scope.getCategoryDropdownValue = () => {
    if (!$scope.currentQuery.category) {
      return '';
    }

    // For Recommended, it is not searchable, default to categoryId given from backend
    if ($scope.currentQuery?.category?.categoryId === menuConstants.categoryTypes.Recommended) {
      const categoryId = $scope.library.defaultCategoryIdForRecommendedSearch;
      if (Number.isInteger(categoryId, 10)) {
        const categoryOption = $scope.navigationMenu.categories.find(cat => {
          return cat.categoryId === categoryId;
        });
        if (categoryOption && categoryOption.name) {
          return categoryOption.name;
        }
      }
    }

    // For Gear, update the category dropdown in the search bar to be the Gear genre
    if (
      $scope.currentQuery.category.categoryId === $scope.library.gearCategoryId &&
      $scope.currentQuery.subcategory &&
      $scope.currentQuery.subcategory.subcategoryId !== $scope.library.defaultGearSubcategoryId
    ) {
      return $scope.currentQuery.subcategory.name;
    }

    return $scope.currentQuery.category.name;
  };

  $scope.updateCategory = (event, clearFilters, clearKeyword, category, subcategory) => {
    if (clearKeyword) {
      $scope.searchLayout.keyword = null;
      $scope.clearTopics();
      $scope.topicBasedBrowsingEnabledForCategory = catalogConstants.topics.categoriesToShowTopics.includes(
        category.categoryId
      );
    }

    if (subcategory) {
      $scope.isLayeredClothingCategory = catalogConstants.layeredClothingSubcategories.includes(
        subcategory.subcategoryId
      );
      event.stopPropagation();
    } else {
      $scope.isLayeredClothingCategory = false;
    }

    $scope.currentQuery.category = category;
    $scope.currentQuery.subcategory = subcategory;
    // if no subcategory was supplied, set the subcategory to the default for the given category
    if (!subcategory) {
      const cat = $scope.getCategoryOption(category);
      if (cat && cat.subcategories) {
        $scope.currentQuery.subcategory = cat.subcategories[0];
      }
    }

    $scope.library.isSplashPage = false;

    if (clearFilters) {
      $scope.onClearFilters(!clearKeyword);
    } else {
      $scope.applySearch(true);
    }
  };

  $scope.updateSort = sort => {
    $scope.data.sortType = sort;
    $scope.applySearch(true);
  };

  $scope.updateSortAggregation = sortAggregation => {
    $scope.data.sortAggregation = sortAggregation;
    $scope.applySearch(true);
  };

  $scope.filterChanged = () => {
    if ($scope.layout.isSearchOptionsOpen) {
      return;
    }

    // Don't apply new search if user selects custom username or custom price range
    // because those require input before applying

    // if selecting custom creator filter and one is not already applied, don't apply new search
    if (
      !$scope.data.customCreatorSelected &&
      !$scope.data.creatorName &&
      $scope.data.creator &&
      $scope.data.creator.userId === $scope.layout.customText
    ) {
      $scope.data.customCreatorSelected = true;
      return;
    }
    $scope.data.isCreatorAGroup = false;
    $scope.data.customCreatorSelected = false;

    // If selecting custom price filter and one is not already applied
    if (
      $scope.data.minPriceBreadcrumb == null &&
      $scope.data.currencyType &&
      $scope.data.currencyType.currencyType === $scope.library.customRobuxFilterId
    ) {
      // if custom price was not previously selected but now it is, don't apply new search
      if (!$scope.data.customPriceSelected) {
        $scope.data.customPriceSelected = true;
        return;
      }
      // otherwise change the price filter to the default
      $scope.data.currencyType = $scope.data.defaultCurrencyValue;
      $scope.data.customPriceSelected = false;
    } else {
      $scope.data.customPriceSelected = false;
    }

    $scope.applySearch(true);
  };

  $scope.applySearch = (fadeResultsWhileLoading, rememberPage) => {
    $scope.layout.isSearchItemsLoaded = false;
    $scope.library.isSplashPage = false;
    $scope.setQuery(rememberPage);
    $scope.getPagedItems(true, fadeResultsWhileLoading);
    $scope.adRefresh();
  };

  $scope.onPriceFilterKeydown = event => {
    // if user hits Enter, perform search
    if (event.key === 'Enter') {
      event.preventDefault();
      $scope.applySearch(true);
    }
  };

  $scope.onCreatorFilterKeydown = event => {
    // if user hits Enter, perform search
    if (event.key === 'Enter') {
      event.preventDefault();
      $scope.applySearch();
    }
  };

  $scope.mobileCategoryClick = (category, forceUpdate) => {
    const { subcategories } = category;
    if (!category || (!forceUpdate && subcategories && subcategories.length > 0)) {
      return;
    }

    if (!subcategories || subcategories.length === 0) {
      $scope.currentQuery.subcategory = null;
    }

    $scope.currentQuery.category = category;
  };

  $scope.getAllFeaturedItems = () => {
    const params = { Category: $scope.getSearchableCategory() };
    $scope.library.isSplashPage = false;
    $scope.data.query = params;
    $scope.queries = utilityService.formatQueries(params);
    $location.search(params);
    $scope.setSelectedNavigationMenu(params.Category);
    $scope.getPagedItems(true, false);
  };

  $scope.showViewAllFeaturedItemsButton = () => {
    const { isSplashPage, initialized, isPhone } = $scope.library;
    return (
      !$scope.landingPageInfiniteScrollEnabled &&
      (isSplashPage || ($scope.isCurrentCategoryRecommended() && initialized && !isPhone))
    );
  };

  $scope.getSearchableCategory = () => {
    const currentId = $scope.currentQuery?.category?.categoryId;
    if ($scope.isCurrentCategoryRecommended()) {
      return $scope.library.defaultCategoryIdForRecommendedSearch;
    }

    return currentId;
  };

  $scope.getViewAllItemsTranslation = categoryId => {
    if (categoryId === menuConstants.categoryTypes.All) {
      return 'Label.AllItems';
    }
    return 'Action.ViewFeaturedItems';
  };

  $scope.getTranslationKeyForViewFeaturedButton = () => {
    return $scope.getViewAllItemsTranslation($scope.getSearchableCategory());
  };

  $scope.showUnavailableFilter = () => {
    return $scope.library && !$scope.library.isPhone;
  };

  $scope.showCurrencyType = currencyType => {
    if (!$scope.currentQuery.category || !currencyType) {
      return false;
    }

    // hide free filter when the category is set to default
    return !(
      $scope.currentQuery.category.categoryId === $scope.library.defaultCategoryId &&
      currencyType.currencyType === $scope.library.freeFilterId
    );
  };

  // A non-searchable category cannot be keyword searched, filtered or sorted in any order
  $scope.isSearchableCategory = category => {
    // for backwards compatibility, we take it that category is searchable by default
    return category?.isSearchable ?? true;
  };

  $scope.categoryClick = (event, category) => {
    // for categories that don't have a subcategory menu, apply new search
    if (!category.subcategories || category.subcategories.length === 0) {
      $scope.clearTopics();
      $scope.topicBasedBrowsingEnabledForCategory = catalogConstants.topics.categoriesToShowTopics.includes(
        category.categoryId
      );
      $scope.library.isSplashPage = false;
      $scope.currentQuery.category = category;
      $scope.currentQuery.subcategory = null;
      $scope.onClearFilters(false);
    }
  };

  $scope.renderShoppingCartButton = () => {
    tryMountCartButton('shopping-cart-btn-entry');
  };

  $scope.categoryNameClick = (event, category) => {
    // Apply new search
    $scope.clearTopics();
    $scope.topicBasedBrowsingEnabledForCategory = catalogConstants.topics.categoriesToShowTopics.includes(
      category.categoryId
    );
    $scope.library.isSplashPage = false;
    $scope.currentQuery.category = category;
    $scope.currentQuery.subcategory = null;
    $scope.onClearFilters(false);

    // Dont close the submenu if it is open
    if (
      document
        .getElementById(`expandable-menu-category-${category.categoryId}`)
        .getAttribute('aria-expanded') === 'true'
    ) {
      event.stopPropagation();
    }
  };

  $scope.expandSelectedCategoryMenu = () => {
    const selectedCategory = $scope.currentQuery.category;

    if (!selectedCategory) {
      return;
    }

    const selectedCategoryDataValue =
      selectedCategory.categoryId === menuConstants.categoryTypes.Recommended
        ? $scope.library.defaultCategoryIdForRecommendedSearch
        : selectedCategory.categoryId;

    const categoryOption = $scope.getCategoryOptionFromCategoryId(selectedCategoryDataValue);
    if (
      !categoryOption ||
      categoryOption.subcategories == null ||
      categoryOption.subcategories.length === 0
    ) {
      return;
    }

    BootstrapWidgets.ShowAccordionMenu(
      $scope.layout.accordionMenuIdPrefix + selectedCategoryDataValue
    );
  };

  $scope.checkShowTopUpWidget = () => {
    const lastClearedTimestamp = localStorageService.getLocalStorage(
      `Roblox.AvatarMarketplace.TopUpClearDate-${CurrentUser.userId}`
    );
    $scope.topUp.showTopUp = Date.now() - lastClearedTimestamp > catalogConstants.topUp.resetTime;
  };

  $scope.onTopUpClear = () => {
    localStorageService.setLocalStorage(
      `Roblox.AvatarMarketplace.TopUpClearDate-${CurrentUser.userId}`,
      Date.now()
    );
    $scope.topUp.showTopUp = false;
  };

  $scope.isCurrentCategoryRecommended = () => {
    const currentId = $scope.currentQuery?.category?.categoryId;
    return currentId === menuConstants.categoryTypes.Recommended;
  };

  const watchCurrencyTypeInitialized = $scope.$watch('data.currencyType', newValue => {
    if (
      newValue &&
      newValue.excludePriceSorts &&
      $scope.data.sortType &&
      $scope.data.sortType.isPriceRelated
    ) {
      $scope.data.sortType = $scope.data.defaultSortValue;
    }
  });

  const watchCategoryInitialized = $scope.$watch('data.category', newValue => {
    if (newValue) {
      // Set currency filter to default if new category is the default and the current currency filter is Free
      if (
        newValue.categoryId === $scope.library.defaultCategoryId &&
        $scope.data.currencyType &&
        $scope.data.currencyType.currencyType === $scope.library.freeFilterId
      ) {
        $scope.data.currencyType = $scope.library.defaultCurrencyType;
        $scope.data.showFreeBreadcrumb = false;
        $scope.$apply();
      }
    }
  });

  $scope.checkUseReactCatalog = () => {
    const searchParams = $location.search();
    return searchParams.useReactCatalog === 'true';
  };

  $scope.setupReactCatalog = () => {
    // Debounce rendering function to avoid frequent re-renders
    $scope.renderReactCatalogComponent = () => {
      if (!$scope.useReactCatalog) {
        return;
      }
      if ($scope.renderReactCatalogDebounce) {
        $timeout.cancel($scope.renderReactCatalogDebounce);
      }
      $scope.renderReactCatalogDebounce = $timeout(() => {
        renderCatalogReactComponent();
      }, 100); // Debounce delay
    };

    $scope.renderReactCatalogComponent();
  };

  $scope.$on('$destroy', () => {
    if (watchCurrencyTypeInitialized) {
      watchCurrencyTypeInitialized();
    }

    if (watchCategoryInitialized) {
      watchCategoryInitialized();
    }

    if ($scope.useReactCatalog) {
      unmountCatalogReactComponent();
      if ($scope.renderReactCatalogDebounce) {
        $timeout.cancel($scope.renderReactCatalogDebounce);
      }
    }
  });

  $scope.initialize();
}

catalogModule.controller('catalogController', catalogController);

export default catalogController;
