import catalogModule from '../catalogModule';

function priceFilters(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.priceFiltersTemplate
  };
}

catalogModule.directive('priceFilters', priceFilters);

export default priceFilters;
