import catalogModule from '../catalogModule';

function shimmerContainer(catalogConstants) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: catalogConstants.templateUrls.shimmerContainer
  };
}

catalogModule.directive('shimmerContainer', shimmerContainer);

export default shimmerContainer;
