import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import CatalogPageContainer from '../containers/CatalogPageContainer';

/** *****************************************************************************
 NOTE:
   These mounting functions should only be used if you aren't using React.
   Otherwise, you should render the CatalogPage directly within your app.
****************************************************************************** */

function renderApp() {
  const entryPoint = document.getElementById('catalog-react-container');
  if (entryPoint) {
    render(<CatalogPageContainer />, entryPoint);
  } else {
    // Recursively call renderApp if target div not found
    // Callback will be triggered before every repaint
    window.requestAnimationFrame(() => renderApp());
  }
}

/*
Tries to mount and render the CatalogPage React component.
*/
function renderCatalogReactComponent(): void {
  renderApp();
}

export function unmountCatalogReactComponent(): void {
  const container = document.getElementById('catalog-react-page');
  if (container) {
    unmountComponentAtNode(container);
  }
}

export default renderCatalogReactComponent;
