import catalogModule from '../catalogModule';

function universalAppConfigurationService(httpService, catalogConstants) {
  'ngInject';

  return {
    getVngBuyRobuxPolicy() {
      return httpService.httpGet(catalogConstants.endpoints.getVngBuyRobuxBehavior);
    }
  };
}
catalogModule.factory('universalAppConfigurationService', universalAppConfigurationService);

export default universalAppConfigurationService;
