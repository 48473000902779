export const shoppingCartConstants = {
  maxSelectedItems: 20
};

export const urlConfigs = {
  assetRootUrlTemplate: 'catalog',
  bundleRootUrlTemplate: 'bundles'
};

export const itemTypes = {
  bundle: 'bundle',
  asset: 'asset'
};

export default shoppingCartConstants;
